let characters = new Array(" không "," một "," hai "," ba "," bốn "," năm "," sáu "," bảy "," tám "," chín ");
let moneyArr = new Array( "", " nghìn", " triệu", " tỷ", " nghìn tỷ", " triệu tỷ");

function readNumberHasTreeCharacters(number)
{
  let hundred;
  let dozen;
  let unit;
  let result = "";
  hundred = parseInt(number / 100, 10);
  dozen = parseInt((number % 100) / 10, 10);
  unit = number % 10;
  
  if (hundred == 0 && dozen == 0 && unit == 0) return "";
  
  if (hundred != 0) {
    result += characters[hundred] + " trăm ";
    if ((dozen == 0) && (unit != 0)) result += " linh ";
  }
    
  if ((dozen != 0) && (dozen != 1)) {
    result += characters[dozen] + " mươi";
    if ((dozen == 0) && (unit != 0)) result = result + " linh ";
  }
    
  if (dozen == 1) result += " mười ";
  
  switch (unit)
  {
    case 1:
      if ((dozen != 0) && (dozen != 1)) {
        result += " mốt ";
      } else {
        result += characters[unit];
      }
      break;
    case 5:
      if (dozen == 0) {
        result += characters[unit];
      } else {
        result += " lăm ";
      }
      break;
    default:
      if (unit != 0) {
        result += characters[unit];
      }
      break;
  }
  return result;
}

function ConvertMoneyFromNumberToString(money)
{
  let count = 0;
  let i = 0;
  let number = 0;
  let result = "";
  let tmp = "";
  let position = new Array();
  
  if(money < 0) return "Số tiền âm !";
  
  if(money == 0) return "Không đồng !";
  
  if(money > 0) {
    number = money;
  } else {
    number = -money;
  }
  
  if (money > 8999999999999999) {
    return "Số quá lớn!";
  }
    
  position[5] = Math.floor(number / 1000000000000000);
  if(isNaN(position[5])) {
    position[5] = "0";
  }
      
  number = number - parseFloat(position[5].toString()) * 1000000000000000;
  position[4] = Math.floor(number / 1000000000000);
  
  if(isNaN(position[4])) {
    position[4] = "0";
  }
  number = number - parseFloat(position[4].toString()) * 1000000000000;
  position[3] = Math.floor(number / 1000000000);
   
  if(isNaN(position[3])) {
    position[3] = "0";
  }
      
  number = number - parseFloat(position[3].toString()) * 1000000000;
  position[2] = parseInt(number / 1000000, 10);
   
  if(isNaN(position[2])) {
    position[2] = "0";
  }
      
  position[1] = parseInt((number % 1000000) / 1000, 10);
  
  if(isNaN(position[1])) {
    position[1] = "0";
  }
      
  position[0] = parseInt(number % 1000, 10);
  if(isNaN(position[0])) {
    position[0] = "0";
  }
        
  if (position[5] > 0) {
    count = 5;
  } else if (position[4] > 0) {
    count = 4;
  } else if (position[3] > 0) {
    count = 3;
  } else if (position[2] > 0) {
    count = 2;
  } else if (position[1] > 0) {
    count = 1;
  } else {
    count = 0;
  }
    
  for (i = count; i >= 0; i--)
  {
    tmp = readNumberHasTreeCharacters(position[i]);
    result += tmp;
    if (position[i] > 0) result += moneyArr[i];
    if ((i > 0) && (tmp.length > 0)) result += ',';//&& (!string.IsNullOrEmpty(tmp))
  }
    
  if (result.substring(result.length - 1) == ',') {
    result = result.substring(0, result.length - 1);
  }
  
  result = result.substring(1,2).toUpperCase() + result.substring(2);
  result = result + ' đồng';//.substring(0, 1);//.toUpperCase();// + KetQua.substring(1);
  if(money > 0) {
    const len = money.toString().length;
    switch(len) {
      case 1:
        result = result + ' chẵn';
        break;
      case 2:
        if(money % 10 === 0) {
          result = result + ' chẵn';
        }
        break;
      case 3:
        if(money % 100 === 0) {
          result = result + ' chẵn';
        }
        break;
      case 4:
        if(money % 1000 === 0) {
          result = result + ' chẵn';
        }
        break;
      case 5:
        if(money % 10000 === 0) {
          result = result + ' chẵn';
        }
        break;
      case 6:
        if(money % 100000 === 0) {
          result = result + ' chẵn';
        }
        break;
      case 7:
        if(money % 1000000 === 0) {
          result = result + ' chẵn';
        }
        break;
      case 8:
        if(money % 10000000 === 0) {
          result = result + ' chẵn';
        }
        break;
      case 9:
        if(money % 10000000 === 0) {
          result = result + ' chẵn';
        }
        break;
      case 10:
        if(money % 10000000 === 0) {
          result = result + ' chẵn';
        }
        break;
      default:
        break;
    }
  }
  return result;
}

export default ConvertMoneyFromNumberToString;