import antdEN from "antd/lib/locale-provider/vi_VN";
import vnMessages from "../locales/vi_VN.json";

const VnLang = {
  messages: {
    ...vnMessages
  },
  antd: antdEN,
  locale: 'vi-VN',
};
export default VnLang;
