const APPCONFIG = {
  storage: 'http://27.71.232.147/storage/',
  TRELLO_API_KEY: '39371bd284d4c6a4e034de164de8f7de',
  TRELLO_API_TOKEN: 'ATTAf1936a88ab06e04fb536abbf845b0fb83f5cedf37f60175e01ad9a67b9efdf18563B73D3',
  RECEIPT: {
    TYPE_LIST: [
      {id: 1, name: 'Tạm ứng'},
      {id: 2, name: 'Phiếu thu'},
      {id: 3, name: 'Phiếu chi'},
      {id: 4, name: 'Miễn giảm'},
      {id: 5, name: 'Hoàn trả'}
    ],
    TYPE_OBJ: {
      PHIEU_THU: 1,
      TAM_UNG: 2,
      PHIEU_CHI: 3,
      MIEN_GIAM: 4,
      HOAN_TRA: 5
    },
    TYPE_OBJ2: {
      1: 'Phiếu thu',
      2: 'Tạm ứng',
      3: 'Phiếu chi',
      4: 'Miễn giảm',
      5: 'Hoàn trả'
    },
    SUB_TYPE_OBJ: {
      PHIEU_THU_TONG_HOP: 20
    },
    PAYMENT_LIST: [
      {id: 1, name: 'TIỀN MẶT'},  
      {id: 2, name: 'CHUYỂN KHOẢN'},
      {id: 3, name: 'Ví điện tử'},
      {id: 4, name: 'Trực tiếp'},
      {id: 5, name: 'Voucher'}
    ],
    PAYMENT_OBJ: {
      TIEN_MAT: 1,
      CHUYEN_KHOAN: 2,
      VI_DIEN_TU: 3,
      TRUC_TIEP: 4,
      VOUCHER: 5
    },
    PAYMENT_OBJ2: {
      1: 'Tiền mặt',
      2: 'Chuyển khoản',
      3: 'Ví điện tử',
      4: 'Trực tiếp',
      5: 'Voucher',
    },
    STATUS: {
      ACTIVE: 1,
      INACTIVE: 0  
    }
  },
  ORDER: {
    STATUS_LIST: [
      {id: 0, name: 'Hủy'},
      {id: 1, name: 'Báo giá'},  
      {id: 2, name: 'Chốt đơn'},
      {id: 3, name: 'Duyệt thanh toán'},
      {id: 4, name: 'Chờ xuất kho'},
      {id: 5, name: 'Xuất kho'},
      {id: 6, name: 'Xuất hóa đơn'}
    ],
    STATUS_OBJ: {
      HUY: 0,
      BAO_GIA: 1,
      CHOT_DON: 2,
      DUYET_KE_TOAN: 3,
      CHO_XUAT_KHO: 4,
      DA_XUAT_KHO: 5,
      XUAT_HOA_DON: 6
    },
    STATUS_OBJ2: {
      0: 'Hủy',
      1: 'Báo giá',
      2: 'Chốt đơn',
      3: 'Duyệt kế toán',
      4: 'Chờ xuất kho',
      5: 'Xuất kho',
      6: 'Xuất hóa đơn',
    },
    STATUS_EXPORT: [
      {id: 0, name: 'Chưa xuất kho'},
      {id: 1, name: 'Xuất kho 1 phần'},  
      {id: 2, name: 'Xuất kho đủ'}
    ],
    STATUS_EXPORT_OBJ: {
      CHUA_XUAT_KHO: 0,
      XUAT_1_PHAN: 1,
      XUAT_DU: 2
    },
    STATUS_EXPORT_OBJ2: {
      0: 'Chưa xuất kho',
      1: 'Xuất 1 phần',
      2: 'Xuất đủ'
    }
  },
  INVOICE: {
    STATUS_OBJ: {
      ACTIVE: 0,
      INACTIVE: 1
    }
  },
  SUPPLIER: {
    APPROVED_ORDER_STATUS_OBJ: {
      '-1': 'Hủy',
      0: 'Chờ duyệt',
      1: 'Đã duyệt',
      2: 'Đã nhập'
    }
  },
  PRODUCT_TYPE: {
    SERVICES: 0,
    SUPPLIES: 1
  },
  LOG: {
    METHOD: {
      CREATE: 1,
      UPDATE: 2,
      DELETE: 3
    }
  },
  STOCK_TRANSFER: {
    TRANSFER_TYPE_RETURN_SUPPLIER: 10
  }
};

export default APPCONFIG;
