import { useEffect, useState } from 'react';
import MyDocument from './Document';

const InvoicePrint = () => {
  const [pdf_delivery] = useState(JSON.parse(window.localStorage.getItem('pdf_delivery')));

  useEffect(() => {
    document.getElementById("root").setAttribute("style","overflow:hidden");
  }, []);

  return (
    <MyDocument 
      dataPdf={pdf_delivery}
    />
  );
};

export default InvoicePrint;