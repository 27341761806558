import store from 'appRedux/store';
import server from 'services/server';
import { API_URI } from 'constants/index';

export const TABLE_PER_PAGE = 100;

const _ = {
  name: 'log',
  state: {
    items: [],
    pagination: {},
    location: {},
  }, // initial state
  reducers: {
    setData(state, payload) {
      return {
        ...state,
        ...payload,
      };
    }
  },
  effects: {
    async getList(queries) {
      const { data } = await server.get(`${API_URI}/logs`, {
        params: {
          ...queries,
          limit: TABLE_PER_PAGE
        },
      });
      this.setData(data);
    },
  },
};

store.addModel(_);
