import React, {useEffect} from "react";
import {Button, Form, Input} from "antd";
import {useSelector} from "react-redux";

import {useAuth} from "authentication";
import { useMountEffect } from 'constants/hooks';
import IntlMessages from "util/IntlMessages";
import AppNotificationContainer from "../components/AppNotificationContainer";
import { STORAGE_UTM_ACCOUNT_TOKEN as TOKEN } from 'constants/index';

import './style.css';

const FormItem = Form.Item;

const SignIn = (props) => {
  const {pathName} = useSelector(({settings}) => settings);
  const {authUser, userLogin, isLoading, error} = useAuth() || [];
  const {
    location: { query },
  } = useSelector((state) => state.router);
  const type = (query?(query.type||''):'in');
  const code = (query?(query.code||''):'');
  const isOT = (query?(query.isOT||''):'');

  useMountEffect(() => {
    const token = window.localStorage.getItem(TOKEN);
    const company = JSON.parse(window.localStorage.getItem('user_company'));
    const session_branch = JSON.parse(window.sessionStorage.getItem('session_branch'));
    if(token && company && session_branch) {
      props.history.push(pathName);
    } else {
      window.localStorage.removeItem(TOKEN);
      window.localStorage.clear();
      window.sessionStorage.clear();
      // window.location.reload();
      if(type && code)
        props.history.push(`./signin?type=${type}&code=${code}&isOT=${isOT}`);
      else
        props.history.push('/signin');
    }
  });

  const onFinish = values => {
    values.username = values.username.toLowerCase();
    values.password = values.password.toLowerCase();
    userLogin(values);
  };
  
  useEffect(() => {
    if (authUser && window.localStorage.getItem('user_branch') && window.localStorage.getItem('user_company')) {
      if(type && code)
        props.history.push(`./check-in-out?type=${type}&code=${code}&isOT=${isOT}`);
      else
        props.history.push(pathName);
    }
  });


  return (
    <div className="gx-app-login-wrap bg" style={{backgroundImage: 'url(/assets/images/login-bg.jpg)', backgroundPosition: 'center',backgroundSize: 'cover', height:'100%'}}>
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content bg-text">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo.jpg" style={{ marginTop: '55px' }} />
            </div>
          </div>
          
          <div className="gx-app-login-content">
            <Form onFinish={onFinish} className="gx-signin-form gx-form-row0">
              <FormItem
                name='username'
                rules={[
                  {
                    required: true, message: 'Vui lòng nhập tên đăng nhập!',
                  }
                ]}
              >
                <Input placeholder="Tên đăng nhập" style={{fontSize: '16px'}}/>
              </FormItem>
              
              <FormItem
                name='password'
                rules={[
                  {
                    required: true, message: 'Vui lòng nhập mật khẩu!'
                  }
                ]}
              >
                <Input type="password" placeholder="Mật khẩu" style={{fontSize: '16px'}}/>
              </FormItem>
              
              <FormItem>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn"/>
                </Button>
              </FormItem>
            </Form>
          </div>
          
          <AppNotificationContainer loading={isLoading} error={error}/>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
