const common = {
  name: 'common',
  state: {
    error: '',
    response: '',
    loading: false,
    title: '',
    message: '',
  },
  reducers: {
    fetchStart(state) {
      return { ...state, error: '', message: '', loading: true };
    },
    fetchSuccess(state) {
      return { ...state, error: '', message: '', loading: false };
    },
    fetchError(state, payload) {
      return {
        ...state,
        loading: false,
        error: payload.error,
        response: payload.response,
        message: '',
      };
    },
    showMessage(state, title, message) {
      return { ...state, error: '', message, title, loading: false };
    },
    hideMessage(state) {
      return { ...state, loading: false, error: '', message: '', title: '' };
    },
  },
  effects: {},
};

export default common;
