import React, {useState, useEffect} from 'react';
import ReactPDF,{ Page, Text, Image, Font, View, Document, StyleSheet, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { DownloadOutlined } from '@ant-design/icons';
import formatMoney from 'util/FormatMoney';
import moment from 'moment';

Font.register({
  family: "Roboto",
  fonts: [
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf"},
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf"},
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"},
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-italic-webfont.ttf", fontStyle: 'italic'},
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bolditalic-webfont.ttf", fontStyle: 'italic'},
  ]
});

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 20,
    fontFamily: 'Roboto',
    fontSize: 14,
    // textTransform: 'uppercase',
    fontWeight: 600
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    fontWeight: 600,
    // backgroundColor: '#ED1C24',
    marginTop: 10,
  },
  image: {
    // marginVertical: 15,
    // marginHorizontal: 100,
    width: 200,
    height: 60
  },
  // pageNumber: {
  //   position: 'absolute',
  //   fontSize: 12,
  //   bottom: 30,
  //   left: 0,
  //   right: 0,
  //   textAlign: 'center',
  //   color: 'grey',
  // },
  main: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  width4: {
    width: '4%'
  },
  width5: {
    width: '5%'
  },
  width8: {
    width: '8%'
  },
  width10: {
    width: '10%'
  },
  width11: {
    width: '11%'
  },
  width12: {
    width: '12%'
  },
  width13: {
    width: '13%'
  },
  width15: {
    width: '15%'
  },
  width18: {
    width: '18%'
  },
  width20: {
    width: '20%'
  },
  width22: {
    width: '22%'
  },
  width25: {
    width: '25%'
  },
  width26: {
    width: '26%'
  },
  width28: {
    width: '28%'
  },
  width29: {
    width: '29%'
  },
  width30: {
    width: '30%'
  },
  width33: {
    width: '33%'
  },
  width34: {
    width: '34%'
  },
  width35: {
    width: '35%'
  },
  width36: {
    width: '36%'
  },
  width38: {
    width: '38%'
  },
  width40: {
    width: '40%'
  },
  width43: {
    width: '43%'
  },
  width44: {
    width: '44%'
  },
  width50: {
    width: '50%'
  },
  width52: {
    width: '52%'
  },
  width56: {
    width: '56%'
  },
  width60: {
    width: '60%'
  },
  width66: {
    width: '66%'
  },
  width67: {
    width: '67%'
  },
  width72: {
    width: '72%'
  },
  width75: {
    width: '75%'
  },
  width78: {
    width: '78%'
  },
  width95: {
    width: '95%'
  },
  width96: {
    width: '96%'
  },
  width100: {
    width: '100%'
  },
  headerAddress: {
    width: '35%',
    fontSize: 12
  },
  height1: {
    height: 30
  },
  headerRight: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    textTransform: 'none'
  },
  logo: {
    width: 150,
    height: 100
  },
  font7: {fontSize: 7},
  font10: {fontSize: 10},
  font12: {fontSize: 12},
  font14: {fontSize: 14},
  content: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  uppercase: {
    textTransform: 'uppercase'
  },
  footer: {
    // position: 'absolute',
    fontSize: 10,
    // bottom: 30,
    // left: 0,
    // right: 0,
    textAlign: 'center',
    paddingVertical: 10
  },
  footerChild: {
    paddingHorizontal: 20
  },
  colorRed: {color: 'red'},
  colorWhite: {color: 'white'},
  colorGold: {color: '#faad14'},
  colorGray: {color: 'gray'},
  textCenter: {textAlign: 'center', paddingHorizontal: 2},
  border: {border: 1, borderBottomStyle: 'dashed'},
  borderR: {borderRight: 1, borderRightStyle: 'dashed'},
  borderTRB: {borderTop: 1, borderRight: 1, borderBottom: 1},
  borderRBL: {borderRight: 1, borderBottom: 1, borderLeft: 1, borderBottomStyle: 'dashed'},
  borderRBL2: {borderRight: 1, borderBottom: 1, borderLeft: 1},
  borderRL: {borderRight: 1, borderLeft: 1},
  table: {fontSize: 10},
  headerTable: {
    flexDirection: 'row',
    // justifyContent: 'flex-end',
    // backgroundColor: '#ED1C24',
  },
  rowTable: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  categoryGroup: {
    backgroundColor: '#A6A6A6'
  },
  cell: {paddingHorizontal: 5, paddingVertical: 3},
  cellTotal: {paddingHorizontal: 5, paddingVertical: 3, fontWeight: 600},
  totalFooter: {backgroundColor: '#2F74B5'},
  textLeft: {
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  textRight: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  textItalic: {
    fontFamily: 'Roboto',
    fontStyle: 'italic',
  },
  fontWeight300: {
    fontWeight: 300
  },
  customer: {
    paddingTop: 50
  },
  bgYellow: {backgroundColor: 'yellow'},
  bgWhite: {backgroundColor: 'white'}
});

const arrNumber = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];
let cateIndex = 0;
let cateIndex2 = 0;
let sumTotal = 0;
// Create Document Component
const ChildDocument = (isMobile, dataPdf) => {
  const company = JSON.parse(window.localStorage.getItem('user_company'));
  const dataTable = dataPdf? dataPdf.products : [];
  const infor = dataPdf? dataPdf : {};
  const fileName = (infor.created_at ? moment(infor.created_at).format('DD-MM-YYYY_') : '_')
                +(infor.name_card?infor.name_card.replaceAll('/','-'):'');
  return <Document title={fileName}>
        {/*<Page wrap={!isMobile} style={styles.body} size={isMobile ? [window.innerWidth * 0.99, window.innerHeight] : 'A4'}>*/}
        <Page orientation='landscape' wrap={!isMobile} style={styles.body}>
          <View style={styles.main}>
            <View style={styles.headerAddress}>
              <View>
                <Text>{company.name}</Text>
              </View>
              <View>
                <Text>{company.address}</Text>
              </View>
              <View>
                <Text>ĐT: {company.phone}</Text>
              </View>
            </View>
            <View style={{...styles.width30, ...styles.textCenter}}>
              <View>
                <Text>Ngày {moment(infor.created_at).format('DD')} tháng {moment(infor.created_at).format('MM')} năm {moment(infor.created_at).format('YYYY')}</Text>
              </View>
              <Text style={styles.title}>
                PHIẾU GIAO HÀNG
              </Text>
            </View>
            <View style={{...styles.width35, ...styles.textRight, paddingRight: 50}}>
              <View>
                <Text>Số: {infor.id}</Text>
              </View>
            </View>
            <View style={{...styles.width30, display: 'none'}}>
              <Image
                style={styles.image}
                src={`${window.location.origin}/assets/images/logo_ccm.png`}
                />
            </View>
          </View>
          
          <View style={{...styles.content, paddingTop: 10, paddingBottom: 5}}>
            <View style={styles.width100}>
              <Text>KHÁCH HÀNG: {infor.name_card || ''}</Text>
            </View>
          </View>
          <View style={{...styles.content, paddingBottom: 5}}>
            <View style={styles.width100}>
              <Text>ĐỊA CHỈ GIAO HÀNG: {infor.address || ''}</Text>
            </View>
          </View>
          <View style={{...styles.content, paddingBottom: 5}}>
            <View style={styles.width100}>
              <Text>SĐT: {infor.phone_number || ''}</Text>
            </View>
          </View>
          <View style={{...styles.content, paddingBottom: 5}}>
            <View style={{...styles.width50}}>
              <Text>NHÂN VIÊN GIAO HÀNG: {infor.delivery_user_name || ''}</Text>
            </View>
            <View style={{...styles.width50, paddingLeft: 15}}>
              <Text>XE GIAO HÀNG: {infor.license_plate || ''}</Text>
            </View>
          </View>
          
          <View style={{...styles.table}}>
            <View fixed={true} style={{...styles.headerTable, ...styles.border}}>
              <View style={{...styles.width4, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                <Text>STT</Text>
              </View>
              <View style={{...styles.width43, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                <Text>TÊN SẢN PHẨM</Text>
              </View>
              <View style={{...styles.width4, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                <Text>SL</Text>
              </View>
              <View style={{...styles.width5, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                <Text>ĐVT</Text>
              </View>
              <View style={{...styles.width44, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                <Text>QUY CÁCH</Text>
              </View>
            </View>

            {dataTable.length && dataTable.map((item, index) => {
              const children = item.products || [];
							let stt = 1;
							let total = 0;
							let don_gia = 0;
							let giam_gia = 0;
							let vat = 0;
            
              if(item.status == 1 && item.quantity > 0) {
                total = total + parseFloat(item.total_amount);
                sumTotal = sumTotal + parseFloat(item.total_amount);
                  
                if(Number(item.discount) > 0) {
                  giam_gia = Number(item.discount);
                } else {
                  giam_gia = 0;
                }
                
                if(Number(item.vat) > 0) {
                  vat = Number(item.vat);
                } else {
                  vat = 0;
                }
              
                if(Number(item.total_amount) > 0) {
                  if(giam_gia > 0)
                    don_gia = Math.round(Number(item.total_amount) / (((100 + vat) / 100) * ((100 - giam_gia) / 100) * parseFloat(item.quantity)));
                  else
                    don_gia = Math.round(Number(item.total_amount) / (((100 + vat) / 100) * parseFloat(item.quantity)));
                } else {
                  don_gia = 0;
                }
              }

              return (
                <>
                  { item.status &&
                    <View wrap={false} style={{...styles.rowTable, ...styles.borderRBL}}>
                      <View style={{...styles.width4, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text>{index+1}</Text>
                      </View>
                      <View style={{...styles.width43, ...styles.textLeft, ...styles.borderR, ...styles.cell}}>
                        <Text>{item.product_name}</Text>
                      </View>
                      <View style={{...styles.width4, ...styles.textRight, ...styles.borderR, ...styles.cell}}>
                        <Text>{item.quantity ? formatMoney(item.quantity) : 0}</Text>
                      </View>
                      <View style={{...styles.width5, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text>{item.unit_name}</Text>
                      </View>
                      <View style={{...styles.width44, ...styles.textLeft, ...styles.borderR, ...styles.cell, ...styles.uppercase}}>
                        <Text>{item.packing}</Text>
                      </View>
                    </View>
                  }
                  
                  {/* { stt > 1 &&
                    <View wrap={false} style={{...styles.rowTable, ...styles.borderRBL2, ...styles.colorRed,...styles.textItalic}}>
                      <View style={{...styles.width78, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text>Tổng cộng:</Text>
                      </View>
                      <View style={{...styles.width10, ...styles.cell, ...styles.textRight}}>
                        <Text>{total ? formatMoney(total) : 0}</Text>
                      </View>
                      <View style={{...styles.width12, ...styles.cell}}></View>
                    </View>
									} */}
                </>
              );
            })}
            
            { /*sumTotal > 0 &&
              <View wrap={false} style={{...styles.rowTable, ...styles.borderRBL2, ...styles.totalFooter, ...styles.colorWhite, ...styles.textItalic}}>
                <View style={{...styles.width78, ...styles.textCenter, ...styles.borderR, ...styles.cellTotal}}>
                  <Text>Tổng cộng các hạng mục cần thay thế:</Text>
                </View>
                <View style={{...styles.width10, ...styles.cellTotal, ...styles.textRight}}>
                  <Text>{sumTotal ? formatMoney(sumTotal) : 0}</Text>
                </View>
                <View style={{...styles.width12, ...styles.cell}}></View>
              </View>
					  */}
{/* 					  
					  { sumTotal > 0 &&
					    <>
                <View wrap={false} style={{...styles.rowTable, ...styles.borderRL, ...styles.totalFooter, ...styles.colorWhite, ...styles.textItalic}}>
                  { Number(infor.vat) == 0 
                    ?
                    <View style={{...styles.width56, ...styles.cell, ...styles.bgWhite}}></View>
                    :
                    <View style={{...styles.width52, ...styles.cell, ...styles.bgWhite}}></View>
                  }
                  { Number(infor.vat) == 0 
                    ?
                    <View style={{...styles.width22, ...styles.textRight, ...styles.borderR, ...styles.cellTotal}}>
                      <Text>Tổng </Text>
                      <Text style={{ textTransform: 'none' }}>(tổng trước giảm giá, trước VAT):</Text>
                    </View>
                    :
                    <View style={{...styles.width26, ...styles.textRight, ...styles.borderR, ...styles.cellTotal}}>
                      <Text>Tổng </Text>
                      <Text style={{ textTransform: 'none' }}>(tổng trước giảm giá, trước VAT):</Text>
                    </View>
                  }
                  <View style={{...styles.width10, ...styles.cellTotal, ...styles.textRight}}>
                    <Text>{sumTotal ? formatMoney(sumTotal + Number(infor.discount) - Number(infor.vat)) : 0}</Text>
                  </View>
                  <View style={{...styles.width12, ...styles.cell}}></View>
                </View>
                
                { Number(infor.discount) > 0 &&
                  <View wrap={false} style={{...styles.rowTable, ...styles.borderRL, ...styles.totalFooter, ...styles.colorWhite, ...styles.textItalic}}>
                    { Number(infor.vat) == 0 
                      ?
                      <View style={{...styles.width56, ...styles.cell, ...styles.bgWhite}}></View>
                      :
                      <View style={{...styles.width52, ...styles.cell, ...styles.bgWhite}}></View>
                    }
                    { Number(infor.vat) == 0 
                      ?
                      <View style={{...styles.width22, ...styles.textRight, ...styles.borderR, ...styles.cellTotal}}>
                        <Text>Giảm giá:</Text>
                      </View>
                      :
                      <View style={{...styles.width26, ...styles.textRight, ...styles.borderR, ...styles.cellTotal}}>
                        <Text>Giảm giá:</Text>
                      </View>
                    }
                    <View style={{...styles.width10, ...styles.cellTotal, ...styles.textRight}}>
                      <Text>{infor.discount ? formatMoney(infor.discount) : 0}</Text>
                    </View>
                    <View style={{...styles.width12, ...styles.cell}}></View>
                  </View>
                }
                
                { Number(infor.vat) > 0 &&
                  <View wrap={false} style={{...styles.rowTable, ...styles.borderRL, ...styles.totalFooter, ...styles.colorWhite, ...styles.textItalic}}>
                    <View style={{...styles.width52, ...styles.cell, ...styles.bgWhite}}></View>
                    <View style={{...styles.width26, ...styles.textRight, ...styles.borderR, ...styles.cellTotal}}>
                      <Text>VAT:</Text>
                    </View>
                    <View style={{...styles.width10, ...styles.cellTotal, ...styles.textRight}}>
                      <Text>{infor.vat ? formatMoney(infor.vat) : 0}</Text>
                    </View>
                    <View style={{...styles.width12, ...styles.cell}}></View>
                  </View>
                }
                
                <View wrap={false} style={{...styles.rowTable, ...styles.borderRBL2, ...styles.totalFooter, ...styles.colorGold, ...styles.textItalic}}>
                  { Number(infor.vat) == 0 
                    ?
                    <View style={{...styles.width56, ...styles.cell, ...styles.bgWhite}}></View>
                    :
                    <View style={{...styles.width52, ...styles.cell, ...styles.bgWhite}}></View>
                  }
                  { Number(infor.vat) == 0 
                    ?
                    <View style={{...styles.width22, ...styles.textRight, ...styles.borderR, ...styles.cellTotal}}>
                      <Text>Tổng:</Text>
                    </View>
                    :
                    <View style={{...styles.width26, ...styles.textRight, ...styles.borderR, ...styles.cellTotal}}>
                      <Text>Tổng:</Text>
                    </View>
                  }
                  <View style={{...styles.width10, ...styles.cellTotal, ...styles.textRight}}>
                    <Text>{sumTotal ? formatMoney(sumTotal) : 0}</Text>
                  </View>
                  <View style={{...styles.width12, ...styles.cell}}></View>
                </View>
              </>
					  } */}
          </View>

          <View style={styles.main}>
            <View style={{...styles.width33, ...styles.textLeft}}>
              <Text>{moment().format('DD/MM/YYYY HH:mm')}</Text>
            </View>
            <View style={{...styles.width33, ...styles.textCenter}}>
              <View style={{flexDirection: 'column'}}>
                <View style={{...styles.width100}}>
                  <Text>Người nhận hàng</Text>
                </View>
                <View style={{...styles.width100}}>
                  <Text>(Ký, họ tên)</Text>
                </View>
              </View>
            </View>
            {/* <View style={{...styles.width33}}>
              <View style={{...styles.width100, flexDirection: 'row'}}>
                <View style={{...styles.width50, ...styles.textRight}}>
                  <Text>Thành tiền:</Text>
                </View>
                <View style={{...styles.width50, ...styles.textRight, fontWeight: 'bold'}}>
                  <Text>
                    {sumTotal ? formatMoney(sumTotal) : 0}
                  </Text>
                </View>
              </View>
              <View style={{...styles.width100, flexDirection: 'row'}}>
                <View style={{...styles.width50, ...styles.textRight}}>
                  <Text>Đã trả:</Text>
                </View>
                <View style={{...styles.width50, ...styles.textRight, fontWeight: 'bold'}}>
                  <Text>
                    
                  </Text>
                </View>
              </View>
              <View style={{...styles.width100, flexDirection: 'row'}}>
                <View style={{...styles.width50, ...styles.textRight}}>
                  <Text>Còn lại:</Text>
                </View>
                <View style={{...styles.width50, ...styles.textRight, fontWeight: 'bold'}}>
                  <Text>
                    
                  </Text>
                </View>
              </View>
              <View style={{...styles.width100, flexDirection: 'row'}}>
                <View style={{...styles.width50, ...styles.textRight}}>
                  <Text>Số dư cũ:</Text>
                </View>
                <View style={{...styles.width50, ...styles.textRight, fontWeight: 'bold'}}>
                  <Text>
                    
                  </Text>
                </View>
              </View>
            </View> */}
          </View>

          {/* <View style={styles.main}>
            <View style={{...styles.width66}}>
              <Text>{moment().format('DD/MM/YYYY HH:mm')}</Text>
            </View>

            <View style={{...styles.width33, ...styles.textRight}}>
              <View style={{...styles.width100, flexDirection: 'row'}}>
                <View style={{...styles.width50, ...styles.textRight}}>
                  <Text>Tổng cộng:</Text>
                </View>
                <View style={{...styles.width50, ...styles.textRight, fontWeight: 'bold'}}>
                  <Text>
                    
                  </Text>
                </View>
              </View>
            </View>
          </View> */}
        
        </Page>
      </Document>
}

const MyDocument = ({isMobile, dataPdf}) => {
  // const ccmLogo = { uri: `${window.location.origin}/assets/images/logo_ccm.png`, method: 'GET', headers: {"Cache-Control": "no-cache"}, body: '' };
  const infor = dataPdf? dataPdf : {};
  const fileName = (infor.created_at ? moment(infor.created_at).format('DD-MM-YYYY_') : '_')
                +(infor.name_card?infor.name_card.replaceAll('/','-'):'')+".pdf";
  const document = ChildDocument(isMobile, dataPdf);
  return (
    <><div style={{position: 'absolute', right: '87px', top: '10px', padding: '10px'
                    , background: '#323639'}}>
      <PDFDownloadLink document={document} 
        fileName={fileName} 
        style={{color: 'white'}}>
        <DownloadOutlined style={{fontWeight: 'bold', fontSize: '20px'}}/></PDFDownloadLink>
      </div>
    <PDFViewer width='100%' height='100%'>
      {document}
    </PDFViewer></>);
};

export default MyDocument;