import React, {useState, useEffect} from "react";
import {Button, Dropdown, Layout, Menu, message, Popover, Drawer} from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import {useDispatch, useSelector} from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import languageData from "../languageData";
import SearchBox from "components/SearchBox";
// import UserInfo from "components/UserInfo";
import UserProfile from "../../Sidebar/UserProfile";
import AppNotification from "components/AppNotification";
import MailNotification from "components/MailNotification";
import HorizontalNav from "../HorizontalNav";
import {Link} from "react-router-dom";
import {toggleCollapsedSideNav} from "../../../appRedux/actions";
import IntlMessages from "../../../util/IntlMessages";
import {TAB_SIZE, NAV_STYLE_FIXED} from "../../../constants/ThemeSetting";
import '../../../routes/Branch/List/model';

const {Header} = Layout;

const menu = (
  <Menu onClick={handleMenuClick}>
    <Menu.Item key="1">Products</Menu.Item>
    <Menu.Item key="2">Apps</Menu.Item>
    <Menu.Item key="3">Blogs</Menu.Item>
  </Menu>
);

function handleMenuClick(e) {
  message.info('Click on menu item.');
}

const InsideHeader = () => {
  const [searchText, setSearchText] = useState('');
  const {locale} = useSelector(({settings}) => settings);
  const navCollapsed = useSelector(({common}) => common.navCollapsed);
  const width = useSelector(({common}) => common.width);
  const { items: listBranch } = useSelector((state) => state.branchList);
  const dispatch = useDispatch();
  const {settings : { onNavStyleChange, switchLanguage }} = dispatch;
  const user = JSON.parse(window.localStorage.getItem('user'));
  const user_company = JSON.parse(window.localStorage.getItem('user_company'));
  const session_branch = JSON.parse(window.sessionStorage.getItem('session_branch'));
  const [open, setOpen] = useState(false);
  
  useEffect(() => {
    getListBranch();
  }, []);
  
  const getListBranch = () => {
    let param = {};
    param.status = '1';
    param.page = 1;
    param.search = null;
    param.company_id = user_company.id;
    
    dispatch.branchList.getList(param);
  };

  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
            dispatch(switchLanguage(language))
          }>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
            <span className="gx-language-text">{language.name}</span>
          </li>
        )}
      </ul>
    </CustomScrollbars>
  );
    
  const settingMenu = () => (
    <span className="gx-pointer" onClick={() => changeSetting()}>Vertical</span>);
  
  const changeSetting = () => {
    onNavStyleChange(NAV_STYLE_FIXED);
  };

  const updateSearchChatUser = (evt) => {
    setSearchText(evt.target.value);
  };
  
  const onClose = () => {
    setOpen(false);
  };
  
  const showDrawer = () => {
    setOpen(true);
  };
  
  const changeBranch = (data) => {
    window.sessionStorage.setItem('session_branch', JSON.stringify(data));
    window.location.reload();
  };
  
  return (
    <div className="gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal">
      {/*<div className="gx-header-horizontal-top">
        <div className="gx-container">
          <div className="gx-header-horizontal-top-flex">
            <div className="gx-header-horizontal-top-left">
              <i className="icon icon-alert gx-mr-3"/>
              <p className="gx-mb-0 gx-text-truncate"><IntlMessages id="app.announced"/></p>
            </div>
            <ul className="gx-login-list">
              <li>Login</li>
              <li>Signup</li>
            </ul>
          </div>
        </div>
      </div>*/}

      <Header
        className="gx-header-horizontal-main">
        <div className="gx-ml-2 gx-mr-2">
          <div className="gx-header-horizontal-main-flex">
            {/*<div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3 6e">
              <i className="gx-icon-btn icon icon-menu"
                 onClick={() => {
                   dispatch(toggleCollapsedSideNav(!navCollapsed));
                 }}
              />
                </div>*/}
            <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo">
              <img alt="" src="/assets/images/logo.jpg" style={{ height: '18px' }} /></Link>
            <Link to="/" className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo">
              <img alt="" src="/assets/images/logo.jpg" style={{ height: '18px' }} /></Link>

            <div style={{width: '33%'}} className="gx-header-horizontal-nav gx-header-horizontal-nav-curve">
              { (user && user.updated_at) &&
                <HorizontalNav/>
              }
            </div>

            <ul className="gx-header-notifications gx-ml-auto">
              {/*<li className="gx-notify gx-notify-search">
                <Popover overlayClassName="gx-popover-horizantal"
                         placement="bottomRight" content={
                  <div className="gx-d-flex"><Dropdown overlay={menu}>
                    <Button>
                      Category <Icon type="down"/>
                    </Button>
                  </Dropdown>
                    <SearchBox styleName="gx-popover-search-bar"
                               placeholder="Search in app..."
                               onChange={updateSearchChatUser}
                               value={searchText}/></div>
                } trigger="click">

                  <span className="gx-pointer gx-d-block"><i className="icon icon-search-new"/></span>

                </Popover>
              </li>

              <li className="gx-notify">
                <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={<AppNotification/>}
                         trigger="click">
                  <span className="gx-pointer gx-d-block"><i className="icon icon-notification"/></span>
                </Popover>
              </li>

              <li className="gx-notify">
                <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
                         content={<MailNotification/>} trigger="click">
                <span className="gx-pointer gx-status-pos gx-d-block">
                <i className="icon icon-chat-new"/>
                <span className="gx-status gx-status-rtl gx-small gx-orange"/>
                </span>
                </Popover>
              </li>
              
              <li className="gx-msg">
                <Popover 
                  overlayClassName="gx-popover-horizantal" 
                  placement="bottomRight"
                  content={settingMenu()} trigger="click"
                >
                  <span className="gx-pointer gx-flex-row gx-align-items-center">
                    <i style={{fontSize: '18px'}} className={`icon icon-setting`}/>
                  </span>
                </Popover>
              </li>
              
              <li className="gx-language">
                <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
                         content={languageMenu()} trigger="click">
              <span className="gx-pointer gx-flex-row gx-align-items-center"><i
                className={`flag flag-24 flag-${locale.icon}`}/>
              </span>
                </Popover>
              </li>*/}
              
              { (user_company && session_branch && user.type == 1) &&
                <li><a onClick={showDrawer}>{session_branch.name}</a></li>
              }
              
              <li className="gx-user-nav"><UserProfile/></li>
            </ul>
          </div>
        </div>
      </Header>
      
      <Drawer
        title="Chi nhánh"
        placement='right'
        closable={false}
        onClose={onClose}
        open={open}
        key='right'
        className='branch-list'
      >
        { listBranch.length > 0 && listBranch.map(item => {
            return (
              <p onClick={() => changeBranch(item)} style={{ cursor: 'pointer', marginLeft: session_branch && session_branch.id == item.id ? '0px' : '19px' }}>{session_branch && session_branch.id == item.id ? <CheckCircleOutlined /> : ''} {item.name}</p>
            );
          })
        }
      </Drawer>
    </div>
  );
};

export default InsideHeader;
