import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Avatar, Popover, Modal} from "antd";
import {Link, useHistory} from "react-router-dom";

import { useAuth } from "../../authentication";
import { STORAGE_UTM_ACCOUNT_TOKEN as TOKEN , API_HOST} from 'constants/index';

import AddUser from '../../routes/Company/AddUser';
import ModalScanQRCode from '../../routes/WorkMonthDate/CheckInOut/ModalScanQRCode';

const UserProfile = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { userSignOut, authUser: { full_name} } = useAuth();
  const user = JSON.parse(window.localStorage.getItem('user'));
  const [img, setImg] = useState(`${API_HOST}/storage/upload/avatar/${user.avatar}`);
  const [visibleModalAddUser, setVisibleModalAddUser] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleModalScan, setVisibleModalScan] = useState(false);
  const [isOT, setIsOT] = useState(1);
  let rowSelected = user;
  const {userList: {getBranchByCompanyId}} = dispatch;
  
  useEffect(() => {
    getBranchByCompanyId({company_id: user.company_id});
  }, []);
  
  const handleLogOut = () => {
    const token = window.localStorage.getItem(TOKEN);
    if (token) {
      userSignOut({token});
    }
    history.push('/signin');
  };
  
  const inforStaff = () => {
    // setVisibleModalAddUser(true);
    setVisible(!visible);
    history.push('/infor-staff');
  };
  
  const changePass = () => {
    history.push('/change-pass');
  };
  
  const handleClickChange = () => {
    setVisible(!visible);
  };

  const onMenuScanClick=(ot)=>{
    setIsOT(ot);
    setVisibleModalScan(true)
  }

  const scanResult=(result)=>{
    window.location.href = result+(isOT?'&isOT=1':'');
  }
  
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={inforStaff}>{user.full_name || ''}</li>   
      {/* <li className="gx-mt-3" onClick={()=>onMenuScanClick(0)}>Quét mã chấm công</li> */}
      <li className="gx-mt-3" onClick={changePass}>Đổi mật khẩu</li>
      <li className="gx-mt-3" onClick={handleLogOut}>Đăng xuất</li>
    </ul>
  );
  
  // const url = `${API_HOST}/${user.avatar}`;
  return (
    <>
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click" open={visible} onOpenChange={handleClickChange}>
        <Avatar 
          // src={`${user.avatar ? url : "https://via.placeholder.com/150"}`} 
          src={img}
          className="gx-size-20 gx-pointer gx-mr-2" alt=""
          // onError={() => setImg('assets/images/avt.jpg')}
          style={{
            color: '#fff',
            backgroundColor: '#13c2c2',
          }}
        >
          { !user.avatar ? Array.from(user.last_name)[0] : '' }
        </Avatar>
        {/*<span className="gx-avatar-name" style={{fontSize: 14}}>{full_name}<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>*/}
      </Popover>
    
      <Modal 
        title={'THÔNG TIN CÁ NHÂN'}
        visible={visibleModalAddUser} 
        className="add-order full-width"
        destroyOnClose={true}
        onCancel={() => setVisibleModalAddUser(false)}
        footer={null}
      >
        <AddUser 
          rowSelected={rowSelected}
          onClose={() => setVisibleModalAddUser(false)}
        />
      </Modal>

      <Modal 
        title={isOT?"QUÉT MÃ TĂNG CA":"QUÉT MÃ CHẤM CÔNG"}
        visible={visibleModalScan} 
        // width={800}
        centered
        destroyOnClose={true}
        onCancel={() => setVisibleModalScan(false)}
        footer={null}
      >
        <ModalScanQRCode text={isOT?"QUÉT MÃ TĂNG CA":"QUÉT MÃ CHẤM CÔNG"}
          onCancel={() => setVisibleModalScan(false)}
          scanResult={(result) => scanResult(result)}
        />
      </Modal>
    </>
  );
};

export default UserProfile;
