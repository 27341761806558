import { Row , Col, Button } from 'antd';

const Confirm = (props) => {
  return (
    <Row>
      <Col span={24}>
        <h3 style={{color: 'inherit'}}>{props.text}</h3>
      </Col>
      <Col span={24}>
        <hr />
      </Col>
      <Col span={24} className="gx-text-right gx-mt-3">
        <Button style={{marginBottom: '0px'}} className='gx-btn gx-text-white btn-green' htmlType="submit"
          onClick={() => props.onConfirm()}>XÁC NHẬN</Button>
        <Button style={{marginBottom: '0px'}} onClick={() => props.onCancel()}>ĐÓNG</Button>
      </Col>
    </Row>
  );
};

export default Confirm;