import React, { useState, useEffect } from "react";
import { Upload, Progress, Modal, message, Row, Col } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import IntlMessages from "util/IntlMessages";
import { Notify } from 'util/notification';
import { API_URI, API_HOST } from 'constants/index';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const UploadImage = (props) => {
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [progress, setProgress] = useState(0);
  
  useEffect(() => {
    if (props.imageName) {
      setDefaultFileList([
        {
          uid: '-1',
          name: props.imageName,
          status: 'done',
          url: `${API_HOST}/storage/upload/avatar/${props.imageName}`
        },
      ]);
    } else {
      setDefaultFileList([]);
    }
  }, [props.imageName]);
  
  const uploadImage = async options => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: event => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      }
    };
    fmData.append("image", file);
    fmData.append("type", props.typeImage);
    
    try {
      const res = await axios.post(
        `${API_URI}/upload`,
        fmData,
        config
      );

      onSuccess("Ok");
      if (res.data.status === 'done') {
        message.success('Uploaded successfully');
        
        props.setImageId(res.data.id);
      }
    } catch (err) {
      onError({ err });
      
      const { data, status } = err.response;
      if (status === 422) {
        const { message } = data.error;
        for (const [key, value] of Object.entries(message)) {
          Notify.warning('Warning', `Uploaded failed: ${value}`);
          // message.error(`Uploaded failed: ${value}`);
          // const errorMessage = intl.formatMessage({id: `${value}`}).toString();
        }
      }
      // message.error(`Uploaded failed: ${err.response.data.errors.file[0]}`);
    }
  };
  
  const handleRemove = () => props.setImageId(null);
  
  const handleCancel = () => setPreviewVisible(false);

  const handleOnChange = ({ file, fileList, event }) => {
    setDefaultFileList(fileList);
  };
  
  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  
  const imgWidth = props.imgWidth || '100%';
  return (
    <div>
      <Upload
        accept="image/*"
        customRequest={uploadImage}
        onPreview={handlePreview}
        onChange={handleOnChange}
        onRemove={handleRemove}
        listType="picture-card"
        fileList={defaultFileList}
        // defaultFileList={defaultFileList}
        className="image-upload-grid gx-text-center"
      >
        {defaultFileList.length >= 1 ? null : uploadButton}
      </Upload>
      {progress > 0 ? <Progress percent={progress} /> : null}
      
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
        className="add-order"
      >
        <Row justify="center">
          <Col>
            <img alt="example" style={{ width: imgWidth }} src={previewImage} />
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default UploadImage;
