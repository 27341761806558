import React from "react";
import {useDispatch} from "react-redux";
import {Popover} from 'antd';
import { NAV_STYLE_INSIDE_HEADER_HORIZONTAL} from "../../constants/ThemeSetting";

const AppsNavigation = () => {
  const dispatch = useDispatch();
  const {settings : { onNavStyleChange }} = dispatch;
  
  const settingMenu = () => (
    <span className="gx-pointer" onClick={() => changeSetting()}>Horizontal</span>);
  
  const changeSetting = () => {
    onNavStyleChange(NAV_STYLE_INSIDE_HEADER_HORIZONTAL);
  };
  
  return (
    <ul className="gx-app-nav">
      <li><i className="icon icon-search-new"/></li>
      <li><i className="icon icon-notification"/></li>
      <li><i className="icon icon-chat-new"/></li>
      <li className="gx-language">
        <Popover 
          overlayClassName="gx-popover-horizantal" 
          placement="bottomRight"
          content={settingMenu()} trigger="click"
        >
          <span className="gx-pointer gx-flex-row gx-align-items-center">
            <i style={{fontSize: '18px'}} className={`icon icon-setting`}/>
          </span>
        </Popover>
      </li>
    </ul>
  )
};

export default AppsNavigation;
