import React from "react";
import {useSelector} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const HorizontalNav = () => {
  const navStyle = useSelector(({settings}) => settings.navStyle);
  const pathname = useSelector(({common}) => common.pathname);
  const user = JSON.parse(window.localStorage.getItem('user'));
  const user_menus = JSON.parse(window.localStorage.getItem('user_menus'));

  const getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";
    }
  };

  return (
    <Menu
      // defaultOpenKeys={[defaultOpenKeys]}
      // selectedKeys={[selectedKeys]}   
      overflowedIndicator={<div style={{marginTop: '2px', height: '45px'}}><i className="icon icon-menu" style={{fontSize: '20px'}}/></div>}
      mode="horizontal"
    >
      { user_menus && user_menus.length > 0 && user_menus.map(item => {
          if(item.url) {
            return (
              <Menu.Item key={item.id}>
                <Link to={item.url}><i className="icon icon-widgets"/>
                  <span>{item.name}</span>
                </Link>
              </Menu.Item>
            );
          } else {
            return (
              <SubMenu key={item.id} icon={<i className="icon icon-widgets"/>} title={item.name}>
                { item.children && item.children.length > 0 && item.children.map(itemLv2 => {
                    if(itemLv2.url) {
                      return (
                        <Menu.Item key={itemLv2.id}>
                          <Link to={itemLv2.url}><i className="icon icon-widgets"/>
                            <span>{itemLv2.name}</span>
                          </Link>
                        </Menu.Item>
                      );
                    } else {
                      return (
                        <SubMenu key={itemLv2.id} icon={<i className="icon icon-widgets"/>} title={itemLv2.name}>
                          { itemLv2.children && itemLv2.children.length > 0 && itemLv2.children.map(itemLv3 => {
                              return (
                                <Menu.Item key={itemLv3.id}>
                                  <Link to={itemLv3.url}><i className="icon icon-user"/>
                                    <span>{itemLv3.name}</span>
                                  </Link>
                                </Menu.Item>
                              );
                            })
                          }
                        </SubMenu>
                      );
                    }
                  })
                }
              </SubMenu>
            );
          }
        })
      }
    
      {/*<SubMenu key="test" icon={<i className="icon icon-widgets"/>} title="Test Trello">
        <Menu.Item key="cards">
          <Link to="/cards"><i className="icon icon-widgets"/>
            <span>Hồ sơ thẻ</span>
          </Link>
        </Menu.Item>
      </SubMenu>*/}
      
    </Menu>
  );
};

HorizontalNav.propTypes = {};

export default HorizontalNav;

