import axios from 'axios';
import store from 'appRedux/store';
import { STORAGE_UTM_ACCOUNT_TOKEN as TOKEN } from 'constants/index';

const server = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL || 'http://localhost:8000',
  //withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': true
  }
});

// https://www.npmjs.com/package/axios#interceptors
// Add a request interceptor
server.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    store.dispatch.common.fetchStart(config);
    const token = localStorage.getItem(TOKEN);
    if (token) {
      config.headers.common['Authorization'] = 'Bearer ' + token;
    }

    return config;
  },
  function (error) {
    store.dispatch.common.fetchError(error);
    return Promise.reject(error);
  }
);

// https://www.npmjs.com/package/axios#interceptors
// Add a response interceptor
server.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    store.dispatch.common.fetchSuccess();

    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    store.dispatch.common.fetchError({
      error,
      response: error.response,
    });

    // Do something with response error
    return Promise.reject(error);
  }
);

export default server;
