import React, {useEffect, useState} from "react";
import {Button, Checkbox, Form, Input} from "antd";
import {Link} from "react-router-dom";

import {useDispatch, useSelector} from "react-redux";
import IntlMessages from "util/IntlMessages";
import AppNotificationContainer from "../../../components/AppNotificationContainer";
import './model';

const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;
const plainOptions = ['Teacher', 'Student'];

const SignUp = (props) => {
  const [checkedList, setCheckedList] = React.useState();
  const [indeterminate, setIndeterminate] = React.useState(true);
  const onChange = list => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
  };
  const dispatch = useDispatch();
  const {result}  = useSelector((state) => state.signUp);
  const {response:{data:{error}}} = useSelector((state) => state.common);
  const {response:{status}} = useSelector((state) => state.common);
  const {signUp: loading = false} = useSelector((state) => state.loading.models);
  let message = "";
  if(error && loading === true){
    const {username, email} = error.message;
    message = (username ? username[0] : '') + (email ? email[0] : '');
  }
  const onFinish = values => {
    values.is_student = checkedList[0] ? false : true;
    values.is_teacher = checkedList[1] ? false : true;
    dispatch.signUp.signUp(values);
  };
  useEffect(() => {
    if (loading && result) {
      props.history.push('/');
    }
  });
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src='https://via.placeholder.com/272x395' alt='Neature'/>
            </div>
            <div className="gx-app-logo-wid">
              <h1><IntlMessages id="app.userAuth.signUp"/></h1>
              <p><IntlMessages id="app.userAuth.bySigning"/></p>
              <p><IntlMessages id="app.userAuth.getAccount"/></p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo.png"/>
            </div>
          </div>

          <div className="gx-app-login-content">
            <Form onFinish={onFinish}  className="gx-signup-form gx-form-row0">
              {/*<FormItem
                name='username'
                rules={[
                  {
                    required: true,  message: 'Please input your username!',
                  }
                ]}
              >
                <Input placeholder="Name"/>
              </FormItem>*/}
              
              <FormItem
                name='name'
                rules={[
                  {
                    required: true,  message: 'Please input your name!',
                  }
                ]}
              >
                <Input placeholder="name"/>
              </FormItem>
              
              <FormItem
                name='email'
                rules={[
                  {
                    required: true, type: 'email', message: 'The input is not valid E-mail!',
                  }
                ]}
              >
                <Input placeholder="email"/>
              </FormItem>
              
              <FormItem
                name='password'
                rules={[
                  {
                    required: true, message: 'Please input your Password!',
                  }
                ]}
              >
                <Input type="password" placeholder="Password"/>
              </FormItem>
              <FormItem>
                <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
              </FormItem>
              <FormItem>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signUp"/>
                </Button>
                <span><IntlMessages id="app.userAuth.or"/></span> 
                <Link to="/signin">
                  <IntlMessages id="app.userAuth.signIn"/>
                </Link>
              </FormItem>
            </Form>
          </div>
          <AppNotificationContainer loading={loading} error={message}/>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
