import { routerMiddleware, connectRouter } from 'connected-react-router';
import { init } from '@rematch/core';
import loadingPlugin from '@rematch/loading';

import auth from '../models/auth';
import common from '../models/common';
import settings from '../models/settings';

const createBrowserHistory = require('history').createBrowserHistory;


export const history = createBrowserHistory();

const store = init({
  models: {
    auth,
    common,
    settings
  },
  plugins: [loadingPlugin()],
  redux: {
    reducers: {
      router: connectRouter(history),
      settings: (state = settings.state, action) => {
        switch (action.type) {
          case '@@router/LOCATION_CHANGE':
            return {
              ...state,
              pathname: action.payload.location.pathname,
              navCollapsed: false,
            };

          default:
            return state;
        }
      },
    },
    middlewares: [routerMiddleware(history)],
  },
});

export default store;
