import MyDocument from './Document';
import { useEffect, useState } from 'react';
import {isMobile} from 'react-device-detect';

const PdfRenderer = () => {
  const [dataPdf] = useState(JSON.parse(window.localStorage.getItem('pdf_print_data')));

  useEffect(() => {
    document.getElementById("root").setAttribute("style","overflow:hidden");
    
  }, []);
 
  return (
    <MyDocument 
      dataPdf={dataPdf}
      isMobile={isMobile}
    />
  );
};

export default PdfRenderer;