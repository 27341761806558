import { useEffect, useState } from 'react';
import MyDocument from './Document';

const CashierDailyPrint = () => {
  const [pdf_cashier_daily] = useState(JSON.parse(window.localStorage.getItem('pdf_cashier_daily')));
  useEffect(() => {
    document.getElementById("root").setAttribute("style","overflow:hidden");
  }, []);

  return (
    <MyDocument 
      dataPdf={pdf_cashier_daily}
    />
  );
};

export default CashierDailyPrint;