import store from 'appRedux/store';
import server from 'services/server';
import { API_URI } from 'constants/index'

export const TABLE_PER_PAGE = 12;

const _ = {
  name: 'branchList',
  state: {
    items: [],
    allItems: [],
    pagination: {},
    location: {}
  }, // initial state
  reducers: {
    setData(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    setLocation(state, payload) {
      return {
        ...state,
        location: payload
      }
    }
  },
  effects: {
    async getList(queries) {
      const { data } = await server.get(`${API_URI}/branches`, {
        params: {
          ...queries,
        },
      });
      this.setData(data);
    },
    async getAll() {
      const { data } = await server.get(`${API_URI}/branches/all`);
      this.setData({allItems: data});
    },
    async delete(params) {
      await server.delete(`${API_URI}/branches/${params.id}`);
    },
  },
};

store.addModel(_);
