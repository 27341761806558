import axios from 'axios';
import store from 'appRedux/store';
import server from 'services/server';
import { API_URI } from 'constants/index';

export const TABLE_PER_PAGE = 100;

const _ = {
  name: 'modelTrello',
  state: {
    data: [],
    detail: {},
    checklist: [],
    currentChecklist: 0,
    comment: [],
    loading: false,
    listCard: [],
    listName: {}, 
    lists: [],
    status: false,
    visibleConnectTrello: false,
    listTrelloSaler: [],
    listUpdateCardTrello: [],
    boards: []
  }, // initial state
  reducers: {
    setData(state, payload) {
      return {
        ...state,
        data: payload
      };
    },
    setLoading(state, payload) {
      return {
        ...state,
        loading: payload
      };
    },
    setDetail(state, payload) {
      return {
        ...state,
        detail: payload
      };
    },
    resetDetail(state, payload) {
      return {
        ...state,
        detail: {},
        checklist: [],
        comment: [],
        currentChecklist: 0,
        commentCard: []
      };
    },
    setChecklist(state, payload) {
      return {
        ...state,
        checklist: [...state.checklist, payload],
        currentChecklist: state.currentChecklist + 1
      };
    },
    resetChecklist(state, payload) {
      return {
        ...state,
        checklist: [],
        currentChecklist: 0
      };
    },
    setComment(state, payload) {
      return {
        ...state,
        comment: payload
      };
    },
    setListCard(state, payload) {
      return {
        ...state,
        listCard: payload
      };
    },
    setListName(state, payload) {
      return {
        ...state,
        listName: payload
      };
    },
    setListsTrello(state, payload) {
      return {
        ...state,
        lists: payload
      };
    },
    setCommentCard(state, payload) {
      return {
        ...state,
        commentCard: payload
      };
    },
    setStatusUpdate(state, payload) {
      return {
        ...state,
        status: payload
      };
    },
    setVisibleConnectTrello(state, payload) {
      return {
        ...state,
        visibleConnectTrello: payload
      };
    },
    setListTrelloSaler(state, payload) {
      return {
        ...state,
        listTrelloSaler: payload
      };
    },
    setListUpdateCardTrello(state, payload) {
      return {
        ...state,
        listUpdateCardTrello: payload
      };
    },
    setBoards(state, payload) {
      return {
        ...state,
        boards: payload,
      };
    },
  },
  effects: {
    async getOrderByCustomerId(queries) {
      this.setLoading(true);
      try {
        const { data } = await server.get(`${API_URI}/orders/getByCustomerId`, {
          params: {
            ...queries,
          },
        });
        this.setData(data);
      } catch (error) {}
      this.setLoading(false);
    },
    async getCardTrello(params) {
      this.setLoading(true);
      this.resetChecklist();
      try {
        const { data } = await axios.get(`https://api.trello.com/1/cards/${params.trello_card_id}?key=${params.api_key}&token=${params.token}`);
        this.setDetail(data);
        return data;
      } catch (error) {}
      this.setLoading(false);
    },
    async getChecklistTrello(params) {
      this.setLoading(true);
      try {
        const { data } = await axios.get(`https://api.trello.com/1/checklists/${params.trello_checklist_id}?key=${params.api_key}&token=${params.token}`);
        this.setChecklist(data);
      } catch (error) {}
      this.setLoading(false);
    },
    async getCommentTrello(params) {
      this.setLoading(true);
      try {
        const { data } = await axios.get(`https://api.trello.com/1/cards/${params.trello_card_id}/actions?key=${params.api_key}&token=${params.token}`);
        this.setComment(data);
      } catch (error) {}
      this.setLoading(false);
    },
    async getListCardTrello(params) {
      this.setLoading(true);
      try {
        const { data } = await axios.get(`https://api.trello.com/1/lists/${params.list_id}/cards?customFieldItems=true&key=${params.api_key}&token=${params.token}`);
        this.setListCard(data);
      } catch (error) {}
      this.setLoading(false);
    },
    async getListNameTrello(params) {
      this.setLoading(true);
      try {
        const { data } = await axios.get(`https://api.trello.com/1/lists/${params.list_id}?key=${params.api_key}&token=${params.token}`);
        this.setListName(data);
      } catch (error) {}
      this.setLoading(false);
    },
    async getListsTrello(params) {
      this.setLoading(true);
      try {
        const { data } = await axios.get(`https://api.trello.com/1/boards/${params.board_id}/lists?key=${params.api_key}&token=${params.token}`);
        this.setListsTrello(data);
        return data;
      } catch (error) {}
      this.setLoading(false);
    },
    async saveDataTrello(params) {
      this.setLoading(true);
      try {
        const { data } = await server.post(`${API_URI}/orders/saveDataTrello`, params);
      } catch (error) {}
      this.setLoading(false);
    },
    async updateCardTrello(params) {
      this.setLoading(true);
      try {
        const { data } = await server.post(`${API_URI}/orders/updateCardTrello`, params);
        this.setLoading(false);
        return data;
      } catch (error) {}
      this.setLoading(false);
    },
    async pushUpdateListTrello(params) {
      this.setLoading(true);
      try {
        const { data } = await axios.put(`https://api.trello.com/1/cards/${params.trello_card_id}?key=${params.api_key}&token=${params.token}`, params);
        this.setLoading(false);
        return data;
      } catch (error) {}
      this.setLoading(false);
    },
    async pushCardCommentTrello(params) {
      this.setLoading(true);
      try {
        const { data } = await axios.post(`https://api.trello.com/1/cards/${params.trello_card_id}/actions/comments?key=${params.api_key}&token=${params.token}`, params);
        this.setLoading(false);
        return data;
      } catch (error) {}
      this.setLoading(false);
    },
    async pushRemoveLabelCardTrello(params) {
      const { data } = await axios.delete(`https://api.trello.com/1/cards/${params.trello_card_id}/idLabels/${params.trello_label_id}?key=${params.api_key}&token=${params.token}`, params);
      return data;
    },
    async updateNameCardTrello(params) {
      this.setLoading(true);
      try {
        const { data } = await axios.put(`https://api.trello.com/1/cards/${params.trello_card_id}?key=${params.api_key}&token=${params.token}`, params);
        this.setLoading(false);
        return data;
      } catch (error) {}
      this.setLoading(false);
    },
    async getCommentCardTrello(params) {
      this.setLoading(true);
      try {
        const { data } = await axios.get(`https://api.trello.com/1/cards/${params.trello_card_id}/actions?filter=commentCard&key=${params.api_key}&token=${params.token}`);
        this.setCommentCard(data);
      } catch (error) {}
      this.setLoading(false);
    },
    async updateTimeExpire() {
      this.setLoading(true);
      try {
        const { data } = await server.get(`${API_URI}/orders/updateTimeExpire`);
        this.setStatusUpdate(true);
      } catch (error) {}
      this.setLoading(false);
    },
    async getListTrelloSaler(params) {
      const { data } = await axios.get(`https://api.trello.com/1/boards/${params.board_id}/customFields?key=${params.api_key}&token=${params.token}`);
      if(data.length > 0) {
        data.map(obj => {
          if(obj.name == 'SALES') {
            this.setListTrelloSaler(obj.options);
          }
        });
      }
    },
    async pushCardLabelTrello(params) {
      this.setLoading(true);
      try {
        const { data } = await axios.post(`https://api.trello.com/1/cards/${params.trello_card_id}/idLabels?key=${params.api_key}&token=${params.token}`, params);
        this.setLoading(false);
        return data;
      } catch (error) {}
      this.setLoading(false);
    },
    async getListUpdateCardTrello(params) {
      this.setLoading(true);
      try {
        const { data } = await axios.get(`https://api.trello.com/1/cards/${params.trello_card_id}/actions?filter=updateCard&key=${params.api_key}&token=${params.token}`);
        this.setListUpdateCardTrello(data);
      } catch (error) {}
      this.setLoading(false);
    },
    async updateLabelCardTrello(params) {
      this.setLoading(true);
      try {
        const { data } = await axios.put(`https://api.trello.com/1/cards/${params.trello_card_id}?key=${params.api_key}&token=${params.token}&idLabels=${params.idLabels}`, params);
        this.setLoading(false);
        return data;
      } catch (error) {}
      this.setLoading(false);
    },
    async getDetailTrelloUser(params) {
      const { data } = await axios.get(`https://api.trello.com/1/members/${params.member_id}?key=${params.api_key}&token=${params.token}`);
      return data;
    },
    async getBoardTrello(params) {
      try {
        const { data } = await axios.get(`https://api.trello.com/1/members/me/boards?key=${params.api_key}&token=${params.token}`);
        this.setBoards(data);
      } catch(e) {}
    },
  }
};

store.addModel(_);
