const formatMoney = (money) => {
  money = Math.round(money * 100) / 100;
  return money.toString().replace(/\./g, ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  // return money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const getMoneyFromString = (str) => {
  if(!str.trim()) return 0;
  else return parseFloat(str.trim().replace(/\,/g, ''));
};

const checkInt=(value)=>{
  const re = parseInt(value);
  if(re) return re;
  else return 0;
}

const checkFloat=(value)=>{
  const re = parseFloat(value);
  if(re) return re;
  else return 0;
}

export default formatMoney;
export {formatMoney, getMoneyFromString, checkInt, checkFloat};
