import React from "react";
import {Avatar, Popover} from "antd";
import {useHistory} from "react-router-dom";

import { STORAGE_UTM_ACCOUNT_TOKEN as TOKEN } from 'constants/index'
import { useAuth } from "../../authentication";

const UserInfo = () => {
  
  let history = useHistory();
  const { userSignOut, authUser: { first_name, last_name} } = useAuth();

  const handleLogOut = () => {
    const token = localStorage.getItem(TOKEN);
    if (token) {
     userSignOut({token});
    }
  };
  
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>My Account</li>
      <li>Connections</li>
      <li  onClick={() => handleLogOut()}>Logout</li>
      
    </ul>
  );

  return (
    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
             trigger="click">
      <Avatar src={"https://via.placeholder.com/150"}
              className="gx-avatar gx-pointer" alt=""/>
    </Popover>
  );
};

export default UserInfo;
