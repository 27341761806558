import React, { memo, useEffect } from "react";
import { useDispatch, useSelector, connect } from 'react-redux';
import URLSearchParams from 'url-search-params';
import { Redirect, Route, Switch, useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import {ConfigProvider} from 'antd';
import {IntlProvider} from "react-intl";
import AppLocale from "../../lngProvider";

import MainApp from './MainApp';
import SignIn from '../SignIn';
import SignOut from '../../routes/User/SignOut';
import SignUp from '../../routes/User/SignUp';
import {setInitUrl} from "../../appRedux/actions";
import PdfRenderer from '../../routes/PdfRenderer';
import PdfQuote from '../../routes/Quote/PdfRenderer';
import PdfInvoice from '../../routes/Cashier/InvoicePrint';
import PdfDept from '../../routes/Customer/DeptPdf';
import PdfInventory from '../../routes/Inventory/Pdf';
import CashierDailyPrint from '../../routes/Cashier/ListDaily/Pdf';
import CheckInOut from '../../routes/WorkMonthDate/CheckInOut';
import ScanQRCode from '../../routes/WorkMonthDate/ScanQRCode';
import PdfDelivery from '../../routes/CustomerTransactions/Delivery/Pdf';

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK
} from "../../constants/ThemeSetting";
import CircularProgress from "../../components/CircularProgress";
import {useAuth} from "../../authentication";

const setLayoutType = (layoutType) => {
  if (layoutType === LAYOUT_TYPE_FULL) {
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('framed-layout');
    document.body.classList.add('full-layout');
  } else if (layoutType === LAYOUT_TYPE_BOXED) {
    document.body.classList.remove('full-layout');
    document.body.classList.remove('framed-layout');
    document.body.classList.add('boxed-layout');
  } else if (layoutType === LAYOUT_TYPE_FRAMED) {
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('full-layout');
    document.body.classList.add('framed-layout');
  }
};

const setNavStyle = (navStyle) => {
  if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
    navStyle === NAV_STYLE_DARK_HORIZONTAL ||
    navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
    navStyle === NAV_STYLE_ABOVE_HEADER ||
    navStyle === NAV_STYLE_BELOW_HEADER) {
    document.body.classList.add('full-scroll');
    document.body.classList.add('horizontal-layout');
  } else {
    document.body.classList.remove('full-scroll');
    document.body.classList.remove('horizontal-layout');
  }
};

const RestrictedRoute = ({component: Component, location, authUser, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: {from: location}
          }}
        />}
  />;

const App = memo(() => {
  const dispatch = useDispatch();
  const { locale, themeType, navStyle, layoutType, themeColor, pathName: initURL } = useSelector(
    (state) => state.settings
  );

  const {authUser, isLoadingUser} = useAuth() || [];

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    if (themeColor) {
      let link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = `/css/${themeColor}.min.css`; //This line is changed, this comment is for explaination purpose.

      link.className = 'gx-style';
      document.body.appendChild(link);
    }
  }, [themeColor]);

  useEffect(() => {
    if (initURL === '') {
      dispatch.auth.setInitUrl(location.pathname);
    }
    const params = new URLSearchParams(location.search);

    if (params.has('theme')) {
      dispatch.settings.setThemeType(params.get('theme'));
    }
    if (params.has('nav-style')) {
      dispatch.settings.onNavStyleChange(params.get('nav-style'));
    }
    if (params.has('layout-type')) {
      dispatch.settings.onLayoutTypeChange(params.get('layout-type'));
    }
    setLayoutType(layoutType);
    setNavStyle(navStyle);

    if (themeType === THEME_TYPE_DARK) {
      console.log('eee', initURL);
      // history.push(initURL);
      
      document.body.classList.add('dark-theme');
      document.body.classList.add('dark-theme');
      let link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = '/css/dark_theme.css';
      link.className = 'style_dark_theme';
      document.body.appendChild(link);
    }
  }, []);

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
    // console.log('location.pathname', location.pathname);
  });

  useEffect(() => {
    if (!isLoadingUser && !location.pathname.includes('pdf-view') && !location.pathname.includes('pdf-invoice') && !location.pathname.includes('pdf-dept')
        && !location.pathname.includes('pdf-inventory') && !location.pathname.includes('pdf-cashier-daily') && !location.pathname.includes('pdf-quote')
        && !location.pathname.includes('check-in-out') && !location.pathname.includes('signin')
        && !location.pathname.includes('pdf-delivery')/*&& !location.pathname.includes('scan-qrcode')*/) {
      if (!authUser || !window.localStorage.getItem('user_branch') || !window.localStorage.getItem('user_company') 
          || (!window.sessionStorage.getItem('session_branch') && !location.pathname.includes('scan-qrcode'))/*scan-qrcode khng dung session*/) {
        history.push('/signin');
      } else {
        const user_menus = JSON.parse(window.localStorage.getItem('user_menus'));
        
        if(!user_menus.find(item => item == location.pathname)) {
          history.push('/');
        }
      }
    }
  }, [isLoadingUser, authUser, initURL, history]);


  const currentAppLocale = AppLocale[locale.locale];

  return isLoadingUser ? <CircularProgress/> : (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>
        <Switch>
          <Route exact path='/signin' component={SignIn}/>
          <Route exact path='/signout' component={SignOut}/>
          <Route exact path='/signup' component={SignUp}/>
          <Route exact path='/pdf-view' component={PdfRenderer}/>
          <Route exact path='/pdf-quote' component={PdfQuote}/>
          <Route exact path='/pdf-invoice' component={PdfInvoice}/>
          <Route exact path='/pdf-dept' component={PdfDept}/>
          <Route exact path='/pdf-inventory' component={PdfInventory}/>
          <Route exact path='/pdf-cashier-daily' component={CashierDailyPrint}/>
          <Route exact path='/check-in-out' component={CheckInOut}/>
          <Route exact path='/scan-qrcode' component={ScanQRCode}/>
          <Route exact path='/pdf-delivery' component={PdfDelivery}/>
          <RestrictedRoute path={`${match.url}`} authUser={authUser} location={location} component={MainApp}/>
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  )
});

export default App;
