import React, {useEffect, useState, useSearchParams} from "react";

import { QrReader } from 'react-qr-reader';

const ModalScanQRCode = (props) => {
  const scanResult=(result)=>{
    props.scanResult(result);
  }

  return (
    <QrReader
      onResult={(result, error) => {
        if (!!result) {
          scanResult(result?.text);
        }

        if (!!error) {
          console.info(error);
        }
      }}
      style={{ width: '100%' }}
      constraints={{ facingMode: "environment" }}
    />
  );
};

export default ModalScanQRCode;
