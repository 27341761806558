import React, {useState, useEffect} from 'react';
import ReactPDF,{ Page, Text, Image, Font, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import formatMoney from 'util/FormatMoney';
import convertNumberToText from 'util/ConvertNumberToText';
import isJson from 'util/IsJson';
import moment from 'moment';

Font.register({
  family: "Roboto",
  fonts: [
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf"},
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf"},
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"},
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-italic-webfont.ttf", fontStyle: 'italic'},
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bolditalic-webfont.ttf", fontStyle: 'italic'},
  ]
});

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 20,
    fontFamily: 'Roboto',
    fontSize: 12
  },
  title: {
    fontSize: 16,
    textAlign: 'center',
    fontWeight: 600,
    marginTop: 10,
  },
  main: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  font10: {fontSize: 10},
  font12: {fontSize: 12},
  font14: {fontSize: 14},
  uppercase: {
    textTransform: 'uppercase'
  },
  headerTable: {
    flexDirection: 'row',
    backgroundColor: '#ED1C24',
  },
  rowTable: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  borderR: {borderRight: 1, borderRightStyle: 'dashed'},
  borderRBL: {borderRight: 1, borderBottom: 1, borderLeft: 1, borderBottomStyle: 'dashed'},
  cell: {paddingHorizontal: 5, paddingVertical: 3},
  width10: {
    width: '10%'
  },
  width5: {
    width: '5%'
  },
  width20: {
    width: '20%'
  },
  width21: {
    width: '21%'
  },
  width54: {
    width: '54%'
  },
  width60: {
    width: '60%'
  },
  width70: {
    width: '70%'
  },
  width100: {
    width: '100%'
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 5
  },
  footer: {
    flexDirection: 'row',
    textAlign: 'center',
    paddingVertical: 10
  },
  fontWeight500: {
    fontWeight: 500
  },
  fontWeight700: {
    fontWeight: 700
  },
  fontStyleItalic: {
    fontStyle: 'italic'
  },
  textCenter: {
    textAlign: 'center', 
    // paddingHorizontal: 2
  },
  textRight: {
    textAlign: 'right'
  },
  paddingTop50: {
    paddingTop: 50
  },
});

// Create Document Component
const MyDocument = ({dataPdf}) => {
  let address = '';
  if(dataPdf) {
    address = dataPdf.customer_address;
    if(address && isJson(address)) {
      const obj = JSON.parse(address);
      address = [ obj.address, obj.ward_name, obj.district_name, obj.province_name].filter(a => a).join(', ');
    }
  }
  
  return (
    <PDFViewer width='100%' height='100%'>
      <Document>
        <Page orientation='landscape' wrap={true} style={styles.body}>
          
          <View style={styles.title}>
            <Text>SỐ NHẬT KÝ CHUNG</Text>
          </View>
          
          <View style={{...styles.textCenter, paddingBottom: 20}}>
            <Text>
              <Text style={styles.fontWeight500}>Ngày</Text> <Text>{dataPdf.fromDate ? moment(dataPdf.fromDate).format('DD') : ''}</Text> <Text style={styles.fontWeight500}>tháng</Text> <Text>{dataPdf.fromDate ? moment(dataPdf.fromDate).format('MM') : ''}</Text> <Text style={styles.fontWeight500}>năm</Text> <Text>{dataPdf.fromDate ? moment(dataPdf.fromDate).format('YYYY') : ''}</Text>
            </Text>
          </View>
{/*           
          <View style={styles.content}>
            <Text>
              <Text style={styles.fontWeight500}>Họ và tên người kiểm: </Text>
              <Text>{dataPdf.created_user ? dataPdf.created_user : ''}</Text>
            </Text>
          </View> */}

          <View fixed={true} style={{...styles.headerTable, ...styles.border}}>
            <View style={{...styles.width5, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
              <Text>STT</Text>
            </View>
            <View style={{...styles.width54, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
              <Text>LÝ DO</Text>
            </View>
            <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
              <Text>LOẠI PHIẾU</Text>
            </View>
            <View style={{...styles.width21, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
              <Text>HÌNH THỨC THANH TOÁN</Text>
            </View>
            <View style={{...styles.width10, ...styles.textCenter, flexDirection: 'column', justifyContent: 'center'}}>
              <Text>SỐ TIỀN</Text>
            </View>
          </View>
          {
            dataPdf.dataPdf && dataPdf.dataPdf.map((m, stt)=>{
              return <View wrap={false} style={{...styles.rowTable, ...styles.borderRBL, ...styles.bgYellow}}>
                <View style={{...styles.width5, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                  <Text>{stt+1}</Text>
                </View>
                <View style={{...styles.width54, ...styles.borderR, flexDirection: 'column', ...styles.cell}}>
                  <Text>{m.note}</Text>
                </View>
                <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                  <Text>{m.type==3?"PHIẾU CHI":"PHIẾU THU"}</Text>
                </View>
                <View style={{...styles.width21, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                  <Text>{m.payment==2?"CHUYỂN KHOẢN":"TIỀN MẶT"}</Text>
                </View>
                <View style={{...styles.width10, ...styles.textCenter, flexDirection: 'column', justifyContent: 'center'}}>
                  <Text>{formatMoney(m.total_money)}</Text>
                </View>
              </View>
            })
          }
          
          <View style={styles.textRight}>
            <Text>
              <Text style={styles.fontWeight500}>Ngày</Text> <Text>{dataPdf.fromDate ? moment(dataPdf.fromDate).format('DD') : ''}</Text> <Text style={styles.fontWeight500}>tháng</Text> <Text>{dataPdf.fromDate ? moment(dataPdf.fromDate).format('MM') : ''}</Text> <Text style={styles.fontWeight500}>năm</Text> <Text>{dataPdf.fromDate ? moment(dataPdf.fromDate).format('YYYY') : ''}</Text>
            </Text>
          </View>
          
          <View style={{...styles.footer, ...styles.width100}}>
            <View style={styles.width20}>
              <Text style={styles.fontWeight500}>Người Lập Phiếu</Text>
              <Text style={styles.fontStyleItalic}>(Ký, họ tên)</Text>
              <Text></Text>
            </View>
          </View>
          
          <View style={{...styles.footer, ...styles.width100}}>
            <View style={styles.width20}>
              <Text style={styles.paddingTop50}>{dataPdf.created_user ? dataPdf.created_user : ''}</Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default MyDocument;