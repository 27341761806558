import server from 'services/server';
import { API_URI, STORAGE_UTM_ACCOUNT_TOKEN as TOKEN } from 'constants/index';
const AUTH_URI = `${API_URI}/auth`;

const auth = {
  name: 'auth',
  state: {
    authUser: null
  },
  reducers: {
    setAuthUser(state, payload) {
      return {
        ...state,
        authUser: payload,
      };
    }
  },
  effects: (dispatch) => ({
    /**
     * @param {Object} loginParams
     * @param {Object} loginParams.user
     * @param {Function} loginParams.callbackFun
     */
    async userLogin(loginParams) {
      const { user, callbackFun } =loginParams;
      dispatch.common.fetchStart();
      try {
        const { data, status } = await server.post(`${AUTH_URI}/login`, user);
        if (status === 200) {
          dispatch.common.fetchSuccess();
          window.localStorage.setItem(TOKEN, data.token);
          this.getAuthUser();
          if (callbackFun) callbackFun();
        } else {
          dispatch.common.fetchError(data.error);
        }
      } catch (error) {
        dispatch.common.fetchError(error.message);
      }
    },
    async getAuthUser() {
      dispatch.common.fetchStart();
      try {
        const { data, status } = await server.post(`${AUTH_URI}/me`);
        if (status === 200) {
          dispatch.common.fetchSuccess();
          this.setAuthUser(data.user);
        } else {
          dispatch.common.fetchError(data.error);
        }
      } catch (error) {
        dispatch.common.fetchError(error.message);
      }
    },
  }),
};

export default auth;
