import React, {useState, useEffect} from 'react';
import ReactPDF,{ Page, Text, Image, Font, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import formatMoney from 'util/FormatMoney';
import convertNumberToText from 'util/ConvertNumberToText';
import isJson from 'util/IsJson';
import moment from 'moment';

Font.register({
  family: "Roboto",
  fonts: [
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf"},
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf"},
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"},
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-italic-webfont.ttf", fontStyle: 'italic'},
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bolditalic-webfont.ttf", fontStyle: 'italic'},
  ]
});

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 20,
    fontFamily: 'Roboto',
    fontSize: 12
  },
  title: {
    fontSize: 16,
    textAlign: 'center',
    fontWeight: 600,
    marginTop: 10,
  },
  main: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  font10: {fontSize: 10},
  font12: {fontSize: 12},
  font14: {fontSize: 14},
  uppercase: {
    textTransform: 'uppercase'
  },
  width20: {
    width: '20%'
  },
  width30: {
    width: '30%'
  },
  width50: {
    width: '50%'
  },
  width70: {
    width: '70%'
  },
  width100: {
    width: '100%'
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 5
  },
  footer: {
    flexDirection: 'row',
    textAlign: 'center',
    paddingVertical: 10
  },
  fontWeight500: {
    fontWeight: 500
  },
  fontWeight700: {
    fontWeight: 700
  },
  fontStyleItalic: {
    fontStyle: 'italic'
  },
  textCenter: {
    textAlign: 'center', 
    // paddingHorizontal: 2
  },
  textLeft: {
    textAlign: 'left'
  },
  textRight: {
    textAlign: 'right'
  },
  paddingTop50: {
    paddingTop: 50
  },
  image: {
    width: 100,
    height: 'auto'
  },
  colorRed: {
    color: 'red'
  },
  colorGreen: {
    color: 'green'
  }
});

// Create Document Component
const MyDocument = ({dataPdf}) => {
  const company = JSON.parse(window.localStorage.getItem('user_company'));
  
  let address = '';
  if(dataPdf) {
    address = dataPdf.customer_address;
    if(address && isJson(address)) {
      const obj = JSON.parse(address);
      address = [ obj.address, obj.ward_name, obj.district_name, obj.province_name].filter(a => a).join(', ');
    }
  }
  
  return (
    <PDFViewer width='100%' height='100%'>
      <Document>
        <Page orientation='landscape' wrap={true} style={styles.body}>
          <View style={styles.main}>
            <View style={styles.width70}>
              <View style={styles.fontWeight700}>
                <Text>{dataPdf.company_name}</Text>
              </View>
              
              <View>
                <Text>Địa chỉ: <Text style={styles.fontStyleItalic}>{dataPdf.company_address}</Text></Text>
              </View>
              
              <View>
                <Text style={styles.fontStyleItalic}>MST:</Text>
              </View>
            </View>
            
            <View style={{...styles.width30, ...styles.textCenter}}>
              <View style={styles.fontWeight700}>
                <Text>Mẫu số 02 - TT</Text>
              </View>
              
              <View style={styles.fontStyleItalic}>
                <Text>Ban hành theo Thông tư số:</Text>
                <Text>200/2014/TT - BTC, ngày</Text>
                <Text>22/12/2014 của Bộ Tài Chính</Text>
              </View>
            </View>
          </View>
          
          <View style={styles.title}>
            <Text>{dataPdf.type == 3 ? 'PHIẾU CHI' : 'PHIẾU THU'}</Text>
          </View>
          
          <View style={{...styles.textCenter}}>
            <Text>
              <Text style={styles.fontWeight500}>Ngày</Text> <Text>{dataPdf.date ? moment(dataPdf.date).format('DD') : ''}</Text> <Text style={styles.fontWeight500}>tháng</Text> <Text>{dataPdf.date ? moment(dataPdf.date).format('MM') : ''}</Text> <Text style={styles.fontWeight500}>năm</Text> <Text>{dataPdf.date ? moment(dataPdf.date).format('YYYY') : ''}</Text>
            </Text>
          </View>
          
          <View style={{flexDirection: 'row', marginTop: -30, width: 200}}>
            <View style={{...styles.textCenter, padding: 5, width: 75, border: '2 solid #f44336'
                        , color: '#f44336', borderRadius: 5, fontWeight: 'bold'}}>
              <Text>{dataPdf.accept_pay==1 ? 'ĐÃ' : 'CHƯA'} {dataPdf.type == 3 ? 'CHI' : 'THU'}</Text>
            </View>
            <View style={{...styles.textCenter, padding: 5, width: 110, border: '2 solid #f44336'
                        , color: '#f44336', borderRadius: 5, fontWeight: 'bold', marginLeft: 5}}>
              <Text>{dataPdf.payment==1 ? 'TIỀN MẶT' : 'CHUYỂN KHOẢN'}</Text>
            </View>
          </View>
          
          <View style={{...styles.content, paddingTop: 20}}>
            <Text>
              <Text style={styles.fontWeight500}>Họ và tên người {dataPdf.type == 3 ? 'nhận' : 'nộp'} tiền: </Text>
              <Text>{dataPdf.customer_name ? dataPdf.customer_name : ''} {dataPdf.attach ? ' - ' + dataPdf.attach : ''}</Text>
            </Text>
          </View>
          
            
          <View style={styles.content}>
            <Text>
              <Text style={styles.fontWeight500}>Địa chỉ: </Text>
              <Text>{address}</Text>
            </Text>
          </View>
            
          <View style={styles.content}>
            <Text>
              <Text style={styles.fontWeight500}>Lý do: </Text>
              <Text>{dataPdf.bill_number ? 'Thanh toán hóa đơn số ' + dataPdf.bill_number + ' của ' + dataPdf.customer_name + (dataPdf.attach ? ' - ' + dataPdf.attach : '') : dataPdf.note}</Text>
            </Text>
          </View>
          
          <View style={styles.content}>
            <Text>
              <Text style={styles.fontWeight500}>Số tiền: </Text>
              <Text>{dataPdf.total_money ? formatMoney(dataPdf.total_money) : 0} VND</Text>
            </Text>
          </View>
          
          <View style={styles.content}>
            <Text>
              <Text style={styles.fontWeight500}>Viết bằng chữ: </Text>
              <Text>{dataPdf.total_money ? convertNumberToText(dataPdf.total_money) : ''}</Text>
            </Text>
          </View>
          
          <View style={styles.content}>
            <Text>
              <Text style={styles.fontWeight500}>Kèm theo: </Text>
              <Text>{dataPdf.attach ? dataPdf.attach : ''}</Text>
            </Text>
          </View>
          
          <View style={styles.textRight}>
            <Text>
              <Text style={styles.fontWeight500}>Ngày</Text> <Text>{dataPdf.date ? moment(dataPdf.date).format('DD') : ''}</Text> <Text style={styles.fontWeight500}>tháng</Text> <Text>{dataPdf.date ? moment(dataPdf.date).format('MM') : ''}</Text> <Text style={styles.fontWeight500}>năm</Text> <Text>{dataPdf.date ? moment(dataPdf.date).format('YYYY') : ''}</Text>
            </Text>
          </View>
          
          <View style={{...styles.footer, ...styles.width100}}>
            <View style={styles.width20}>
              <Text style={styles.fontWeight500}>Thủ Trưởng Đơn Vị</Text>
              <Text style={styles.fontStyleItalic}>(Ký, họ tên, đóng dấu)</Text>
              <Text style={{marginTop: 50}}>&nbsp;</Text>
              <Text style={{fontSize: 8, ...styles.textLeft, ...styles.colorRed}}>{dataPdf.accept_date ? moment(dataPdf.accept_date).format('DD/MM/YYYY HH:mm') : ''}</Text>
              <Text style={{fontSize: 8, ...styles.textLeft, ...styles.colorGreen}}>{moment().format('DD/MM/YYYY HH:mm')}</Text>
            </View>
            
            <View style={styles.width20}>
              <Text style={styles.fontWeight500}>Kế Toán Trưởng</Text>
              <Text style={styles.fontStyleItalic}>(Ký, họ tên)</Text>
              <Text></Text>
            </View>
            
            <View style={styles.width20}>
              <Text style={styles.fontWeight500}>Thủ Quỹ</Text>
              <Text style={styles.fontStyleItalic}>(Ký, họ tên)</Text>
              <Text></Text>
            </View>
            
            <View style={styles.width20}>
              <Text style={styles.fontWeight500}>Người Lập Phiếu</Text>
              <Text style={styles.fontStyleItalic}>(Ký, họ tên)</Text>
              <Text style={{marginTop: 50}}>{dataPdf.user_created.toUpperCase()}</Text>
            </View>
            
            <View style={styles.width20}>
              <Text style={styles.fontWeight500}>Người {dataPdf.type == 3 ? 'Nhận' : 'Nộp'} Tiền</Text>
              <Text style={{...styles.fontStyleItalic, marginBottom: 50}}>(Ký, họ tên)</Text>
              <Text>{dataPdf.full_name ? dataPdf.full_name : ''}</Text>
              <Text>{dataPdf.customer_name ? dataPdf.customer_name : ''} {dataPdf.attach ? ' - ' + dataPdf.attach : ''}</Text>
            </View>
          </View>
          
          {/* <View style={{...styles.content, ...styles.width100}}>
            <Image
              style={styles.image}
              src={dataPdf.qrUrl}
            />
          </View> */}
          {/*<View style={{...styles.content, ...styles.width100}}>
            <View style={styles.width50}>
              <Image
                style={styles.image}
                src={`https://img.vietqr.io/image/${company.bank_bin_code}-${company.bank_account}-${company.template_qr}.jpg?amount=${dataPdf.total_money}&accountName=${company.bank_account_name}`}
              />
            </View>
            
            <View style={{...styles.width50}}>
              <View>
                <Text style={{fontSize: 8, ...styles.textLeft, ...styles.colorRed}}>{dataPdf.accept_date ? moment(dataPdf.accept_date).format('DD/MM/YYYY HH:mm') : ''}</Text>
                <Text style={{fontSize: 8, ...styles.textLeft, ...styles.colorGreen}}>{moment().format('DD/MM/YYYY HH:mm')}</Text>
              </View>
            </View>
          </View>*/}
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default MyDocument;