import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from "react-intl";
import { Input, Spin, Form, Button, Select, Row, Col, Switch } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import IntlMessages from "util/IntlMessages";
import { Notify } from 'util/notification';
// import validateFromServer from 'util/ValidateFromServer';
import { useMountEffect } from 'constants/hooks';
import UploadImage from '../../UploadImage';
import Log from 'routes/Log';

import './model';
import '../../User/List/model';
import '../../User/Detail/model';

const DETAIL_PAGE = '/companies';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

// const tailFormItemLayout = {
//   wrapperCol: {
//     xs: {
//       span: 24,
//       offset: 0,
//     },
//     sm: {
//       span: 16,
//       offset: 8,
//     },
//   },
// };

// const formItemStyle = {
//   marginBottom: '5px'
// };

const AddUser = ({onClose, rowSelected={}, changeInfor = false}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {addUser: loading = false, userDetail: loadingUserDetail = false} = useSelector((state) => state.loading.models);
  
  const { id : user_id, full_name, email, phone, username, type, status, company_id, branch_id, image_id, image_name,first_name, last_name } = useSelector((state) => state.userDetail);
  const {branch, condition} = useSelector((state) => state.userList);
  
  const {
    userDetail: {get}, 
    addUser: {create, update},
  } = dispatch;
  
  const [imageId, setImageId] = useState(null);
  const [visibleLog, setVisibleLog] = useState(false);
  
  useMountEffect(() => {
    if (rowSelected.id) {
      get({
        id: rowSelected.id
      });
    }
  });
  
  useEffect(() => {
    if (rowSelected.id) {
      form.setFieldsValue({
        first_name,
        last_name,
        username,
        email,
        phone,
        branch_id,
        image_name,
        type,
        status
      });
      
      setImageId(image_id);
    }
  }, [user_id, first_name, last_name, full_name, email, username, type, status, company_id, branch_id, image_id, image_name ]);
  
  const handleGoBack = (reload = false) => {
    onClose(reload);
  };
  
  const onFinish = async values => {
    console.log(values);
    const user = JSON.parse(window.localStorage.getItem('user'));
    const user_company = JSON.parse(window.localStorage.getItem('user_company'));
    values.create_by = user.id;
    values.company_id = parseInt(user_company.id, 10);
    values.image_id = imageId || null;
    values.first_name = values.first_name.trim();
    values.last_name = values.last_name.trim();
    values.full_name = (values.first_name + ' ' + values.last_name).toUpperCase();
    values.status = values.status?1:0;
    
    if (rowSelected.id) {
      values.id = rowSelected.id;
      
      const res = await update(values);
      if(res[0] === 'success') {
        Notify.success(intl.formatMessage({id: 'title.user'}), 'Cập nhật thành công');
      } else {
        handleError(res[0]);
        return;
      }
      
      // validateFromServer(form, intl, dispatch.addUser.update(values), () => {
      //   Notify.success(intl.formatMessage({id: 'title.user'}), 'Cập nhật thành công');
      // });
    } else {
      const res = await create(values);
      
      if(res[0] === 'success') {
        Notify.success(intl.formatMessage({id: 'title.user'}), intl.formatMessage({id: 'notification.createdUserSuccess'}));
      } else {
        handleError(res[0]);
        return;
      }
      
      // validateFromServer(form, intl, dispatch.addUser.create(values), () => {
      //   Notify.success(intl.formatMessage({id: 'title.user'}), intl.formatMessage({id: 'notification.createdUserSuccess'}));
      // });  
    }
    
    dispatch.userList.getList(condition);
    handleGoBack(); 
  };
  
  const handleError = (error) => {
    if(error.indexOf('validate.usernameUnique') > -1) {
      Notify.success('Cảnh báo', 'Tên đăng nhập đã tồn tại');
    } else if(error.indexOf('validate.emailUnique') > -1) {
      Notify.success('Cảnh báo', 'Email đã tồn tại');
    } else {
      Notify.success('Cảnh báo', error);
    }
  };

  return (
    <Spin spinning={(loading || loadingUserDetail) ? true : false} delay={0}>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Row>
          <Col span={visibleLog ? 18 : 24} className="box-left">
            <Row>
              <Col span={24} className="menu-left">
                <div className="wrap-left">
                  <Button htmlType="submit" style={{ background: '#87d068', color: '#fff' }}>
                    LƯU
                  </Button>
                  {/* <Button onClick={()=>handleGoBack(false)} style={{ background: '#f44336', color: '#fff' }}>
                    HỦY
                  </Button> */}
                </div>
                
                <div className="wrap-right">
                  <div className="lblStatus">
                    <HistoryOutlined onClick={() => setVisibleLog(!visibleLog)} className="gx-pl-3 gx-pr-2 font22 gx-pointer" />
                  </div>
                </div>
                <div className="clr"></div>
              </Col>
            </Row>
            
            <Row className="wrap">
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <UploadImage 
                      typeImage="avatar"
                      setImageId={setImageId}
                      imageName={image_name}
                    />
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          name="first_name"
                          label='Họ'
                          rules={[
                            {
                              required: true,
                              message: <IntlMessages id="validate.requireFullName" />,
                            },
                          ]}
                        >
                          <Input autoComplete="off" style={{ textTransform: 'uppercase' }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          name="last_name"
                          label='Tên'
                          rules={[
                            {
                              required: true,
                              message: <IntlMessages id="validate.requireFullName" />,
                            },
                          ]}
                        >
                          <Input autoComplete="off" style={{ textTransform: 'uppercase' }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                    
                  <Col span={12}>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          name="username"
                          label=<IntlMessages id="title.username" />
                          rules={[
                            {
                              required: true,
                              message: <IntlMessages id="validate.requireUsername" />,
                            },
                          ]}
                        >
                          <Input autoComplete="off" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  
                  {!rowSelected.id &&
                    <Col span={12}>
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            name="password"
                            label=<IntlMessages id="title.password" />
                            rules={[
                              {
                                required: true,
                                message: <IntlMessages id="validate.requirePassword" />,
                              },
                            ]}
                          >
                            <Input type="password" autoComplete="off" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  }
                  <Col span={12}>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          name="email"
                          label=<IntlMessages id="title.email" />
                          rules={[
                            {
                              required: false,
                              type: 'email',
                              message: <IntlMessages id="validate.invalidEmail" />,
                            },
                          ]}
                        >
                          <Input autoComplete="off" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          name="phone"
                          label=<IntlMessages id="title.phone" />
                          rules={[
                            {
                              required: true,
                              message: <IntlMessages id="validate.requirePhone" />,
                            },
                          ]}
                        >
                          <Input autoComplete="off" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          name="branch_id"
                          label=<IntlMessages id="title.branch" />
                          rules={[
                            {
                              required: true,
                              message: <IntlMessages id="validate.requireBranch" />,
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            filterOption={false}
                            style={{width: '100%'}}
                          >
                            { branch.length > 0 && branch.map(item => {
                                return <Option value={item.id} key={item.id}>{item.name}</Option>;
                              })
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          name="type"
                          label=<IntlMessages id="title.role" />
                          rules={[
                            {
                              required: true,
                              message: <IntlMessages id="validate.requireRole" />,
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            filterOption={false}
                            style={{width: '100%'}}
                          >
                            <Option value={1} key={"role_1"}><IntlMessages id="title.administration" /></Option>
                            <Option value={2} key={"role_2"}><IntlMessages id="title.saler" /></Option>
                            <Option value={3} key={"role_3"}><IntlMessages id="title.technicians" /></Option>
                            <Option value={4} key={"role_4"}><IntlMessages id="title.cashier" /></Option>
                            <Option value={5} key={"role_5"}><IntlMessages id="title.warehouse" /></Option>
                            <Option value={6} key={"role_6"}><IntlMessages id="title.accountancy" /></Option>
                            <Option value={7} key={"role_7"}><IntlMessages id="title.manager_product" /></Option>
                            <Option value={8} key={"role_8"}><IntlMessages id="title.sales_apprenticeship" /></Option>
                            <Option value={9} key={"role_9"}><IntlMessages id="title.chief_technicians" /></Option>
                            <Option value={99} key={"role_99"}><IntlMessages id="title.other" /></Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Col span={24}>
                        <Form.Item 
                          initialValue={true} 
                          name="status" 
                          label='Trạng thái' 
                          valuePropName="checked" 
                          // labelCol={{ span: 18 }} 
                          // wrapperCol={{ span: 6 }}
                        >
                          <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            // onChange={(e)=>setUseStatus(e)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                    
                {/*<Row>
                  <Col span={12}>
                    <Form.Item 
                      initialValue={true} 
                      name="status" 
                      label='TRẠNG THÁI' 
                      valuePropName="checked" 
                      // labelCol={{ span: 18 }} 
                      // wrapperCol={{ span: 6 }}
                    >
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        // onChange={(e)=>setUseStatus(e)}
                      />
                    </Form.Item>
                  </Col>
                </Row>*/}
              </Col>
            </Row>
          </Col>
          
          <Col span={6} className="box-right">
            <Row>
              {visibleLog &&
                <Col span={24} className="gx-pl-0 gx-pr-0">
                  <Log 
                    tableName='orders'
                  />
                </Col>
              }
            </Row>
          </Col>
          
        </Row>
      </Form>
    </Spin>
  );
};

export default AddUser;

