import { useEffect, useState } from 'react';
import httpClient from "services/server";
import { API_URI, STORAGE_UTM_ACCOUNT_TOKEN as TOKEN } from 'constants/index';
import { useHistory } from 'react-router-dom';

const AUTH_URI = `${API_URI}/auth`;

export const useProvideAuth = () => {
  const history = useHistory();
  const [authUser, setAuthUser] = useState(null);
  const [createUser, setcreateUser] = useState(null);
  const [error, setError] = useState('');
  const [isLoadingUser, setLoadingUser] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const fetchStart = () => {
    setLoading(true);
    setError('');
  };

  const fetchSuccess = () => {
    setLoading(false);
    setError('');
  };

  const fetchError = (error) => {
    setLoading(false);
    setError(error);
  };

  const userLogin = (user, callbackFun) => {
    fetchStart();
    httpClient
      .post(`${AUTH_URI}/login`, user)
      .then(({ data, status }) => {
        if (status === 200) {
          fetchSuccess();
          window.localStorage.setItem(TOKEN, data.token);
          getAuthUser();
          if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  // const userSignup = (user, callbackFun) => {
  //   fetchStart();
  //   httpClient
  //     .post(`${API_URI}/users`, user)
  //     .then(({ data }) => {
  //       if (data) {
  //         fetchSuccess();
  //         setcreateUser(data);
  //         if (callbackFun) callbackFun();
  //       } else {
  //         fetchError(data.error);
  //       }
  //     })
  //     .catch(function (error) {
  //       fetchError(error.message);
  //     });
  // };

  const sendPasswordResetEmail = (email, callbackFun) => {
    fetchStart();

    setTimeout(() => {
      fetchSuccess();
      if (callbackFun) callbackFun();
    }, 300);
  };

  const confirmPasswordReset = (code, password, callbackFun) => {
    fetchStart();

    setTimeout(() => {
      fetchSuccess();
      if (callbackFun) callbackFun();
    }, 300);
  };

  const renderSocialMediaLogin = () => null;

  const userSignOut = (token, callbackFun) => {
    fetchStart();
    httpClient
      .post(`${AUTH_URI}/logout`,token)
      .then(({ data }) => {
        if (data) {
          fetchSuccess();
          setAuthUser(false);
          if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);
        }
        
        httpClient.defaults.headers.common['Authorization'] = '';
        window.localStorage.removeItem(TOKEN);
        window.localStorage.clear();
        window.sessionStorage.clear();
        
        history.push('/signin');
        window.location.reload();
      })
      .catch(function (error) {
        fetchError(error.message);
        
        httpClient.defaults.headers.common['Authorization'] = '';
        window.localStorage.removeItem(TOKEN);
        window.localStorage.clear();
        window.sessionStorage.clear();
        
        history.push('/signin');
        window.location.reload();
      });
  };

  const getAuthUser = () => {
    fetchStart();
    httpClient
      .post(`${AUTH_URI}/me`)
      .then(({ data }) => {
        if (data.user) {
          fetchSuccess();
          window.localStorage.setItem('user', JSON.stringify(data.user));
          window.localStorage.setItem('user_branch', JSON.stringify(data.branch));
          window.sessionStorage.setItem('session_branch', JSON.stringify(data.branch));
          window.localStorage.setItem('user_company', JSON.stringify(data.company));
          window.localStorage.setItem('user_menus', JSON.stringify(data.menus));
          window.localStorage.setItem('user_url', JSON.stringify(data.url));
          
          setAuthUser(data.user);
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        window.localStorage.removeItem(TOKEN);
        window.localStorage.clear();
        window.sessionStorage.clear();
        httpClient.defaults.headers.common['Authorization'] = '';
        fetchError(error.message);
      });
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.

  useEffect(() => {
    const token = window.localStorage.getItem(TOKEN);
    if (token) {
      httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    httpClient
      .post(`${AUTH_URI}/me`)
      .then(({ data }) => {
        if (data.user) {
          setAuthUser(data.user);
          window.localStorage.setItem('user', JSON.stringify(data.user));
        }
        setLoadingUser(false);
      })
      .catch(function () {
        window.localStorage.removeItem(TOKEN);
        window.localStorage.clear();
        window.sessionStorage.clear();
        httpClient.defaults.headers.common['Authorization'] = '';
        setLoadingUser(false);
      });
  }, []);

  // Return the user object and auth methods
  return {
    isLoadingUser,
    isLoading,
    authUser,
    createUser,
    setcreateUser,
    error,
    setError,
    setAuthUser,
    getAuthUser,
    userLogin,
    // userSignup,
    userSignOut,
    renderSocialMediaLogin,
    sendPasswordResetEmail,
    confirmPasswordReset,
  };
};
