import { notification } from 'antd';

export const openNotification = (severity, message, description) => {
  notification[severity]({
    message,
    description,
  });
};

export const Notify = ['success', 'error', 'info', 'warning', 'warn'].reduce((obj, current) => {
  return {
    ...obj,
    [current]: (message, description) => openNotification(current, message, description),
  };
}, {});
