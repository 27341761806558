import React, {useEffect, useState, useSearchParams} from "react";
import {Modal, Button} from "antd";
import {useSelector, useDispatch} from "react-redux";
import { useHistory } from 'react-router-dom';

import {useAuth} from "authentication";
import { useMountEffect } from 'constants/hooks';
import IntlMessages from "util/IntlMessages";
import { STORAGE_UTM_ACCOUNT_TOKEN as TOKEN } from 'constants/index';
import { Notify } from 'util/notification';
import moment from 'moment';
import ModalScanQRCode from './ModalScanQRCode';
import Confirm from '../../Confirm';
import detectDevice from "util/DetectDevice";

import '../model';
import '../style.css';

const CheckInOut = (props) => {
  const { inOutByEmployeeDate } = useSelector((state) => state.workMonthDate);
  const history = useHistory();
  const { 
    workMonthDate: { getInOutByEmployeeDate, checkInOut }
  } = useDispatch();
  const [login, setLogin] = useState(0);
  const [detail, setDetail] = useState({});
  const [visibleModalScan, setVisibleModalScan] = useState(false);
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);

  const {
    location: { query },
  } = useSelector((state) => state.router);

  const type = (query?(query.type||''):'in');
  const code = (query?(query.code||''):'');
  const isOT = (query?(query.isOT||''):'');

  useMountEffect(() => {
    const token = window.localStorage.getItem(TOKEN);
    if(token) {
      // const type=(query?(query.type||''):'in');
      // if(type == 'in'){
        getInOutByEmployeeDate();
      // }
      // else{
      //   const code = (query?(query.code||''):'');
      //   onCheckInOut(code);
      // }
    }
    else{
      setLogin(1);
    }
  });

  useEffect(()=>{
    if(inOutByEmployeeDate && inOutByEmployeeDate.id){
      if((type == 'in' && inOutByEmployeeDate.time_in) || (type == 'out' && inOutByEmployeeDate.time_out))
        setVisibleModalConfirm(true);
      else{
        // const code = (query?(query.code||''):'');
        onCheckInOut(code);
      }
    }
    else if(inOutByEmployeeDate && inOutByEmployeeDate.error){
      setLogin(1);
    }
  }, [inOutByEmployeeDate]);

  const onCheckInOut = async (code) => {
    try {
      const device = detectDevice();
      const response = await fetch("https://api.ipify.org?format=json");
      const jData = await response.json();
      device.ip = (jData ? jData.ip : "");

      // const type=(query?(query.type||''):'in');
      const result = await checkInOut({scan_code: code, type: type, 
        device: {ip: device.ip, device: `${device.osName} ${device.navPlatform!== 'iPhone' ? device.osVersion : ''}`,
        browser: `${device.browserName} ${device.browserVersion}`}});
      if(result && result.id){
        setLogin(2);
        setDetail(result);
      }
      else{
        setLogin(3);
      }
    } catch (error) {
      setLogin(1);
    }
    setVisibleModalConfirm(false);
  };
  
  const inforStaff = () => {
    history.push('/employee');
  };

  const scanResult=(result)=>{
    onCheckInOut(result);
    setVisibleModalScan(false);
    alert(result);
  }

  const confirmOveride = ()=>{
    // const code = (query?(query.code||''):'');
    onCheckInOut(code);
  }

  const cancelOveride = ()=>{
    setLogin(2);
    setVisibleModalConfirm(false);
  }

  return (
    <div className="gx-app-login-wrap bg check-in-out" style={{backgroundImage: 'url(/assets/images/login-bg.jpg)', backgroundPosition: 'center'
        ,backgroundSize: 'cover', justifyContent: 'center'}}>
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content bg-text">
          <div className="gx-app-logo">
            <img alt="example" src="/assets/images/logo.jpg" style={{ marginTop: '55px' }} />
          </div>
          {
            login==2?<div className="gx-app-login-content" style={{width: '100%', textAlign: 'center', fontSize: '20px'}}>
              <p>{type=='in'?'CHECK IN':'CHECK OUT'} THÀNH CÔNG</p>
              <p>{detail.full_name?detail.full_name:inOutByEmployeeDate.full_name}</p>
              <p>{type=='in'?(detail.time_in?detail.time_in:inOutByEmployeeDate.time_in?inOutByEmployeeDate.time_in:'00:00').substring(0, 5)
                :(detail.time_out?detail.time_out:inOutByEmployeeDate.time_out?inOutByEmployeeDate.time_out:'00:00').substring(0, 5)}</p>
              <p><Button type="primary" onClick={inforStaff}>Thông Tin Nhân Viên</Button></p>
            </div>
          :login==1?<div className="gx-app-login-content" style={{width: '100%', textAlign: 'center', fontSize: '20px'}}>
            <p>BẠN CHƯA ĐĂNG NHẬP VÀO HỆ THỐNG</p><a href={`./signin?type=${type}&code=${code}`}>CLICK ĐỂ ĐĂNG NHẬP</a><p></p>
          </div>
          :login==3?<div className="gx-app-login-content" style={{width: '100%', textAlign: 'center', fontSize: '20px'}}>
            <p>MÃ QR ĐÃ THAY ĐỔI, VUI LÒNG THỬ LẠI</p>
            {/* <a onClick={()=>setVisibleModalScan(true)}>Click To Scan</a> */}
          </div>
          :<div className="gx-app-login-content" style={{width: '100%', textAlign: 'center', fontSize: '20px'}}>
            <p>VUI LÒNG CHỜ...</p>
          </div>
          }
        </div>
      </div>

      <Modal 
        title="QUÉT MÃ CHẤM CÔNG"
        visible={visibleModalScan} 
        // width={800}
        centered
        destroyOnClose={true}
        onCancel={() => setVisibleModalScan(false)}
        footer={null}
      >
        <ModalScanQRCode text="QUÉT MÃ CHẤM CÔNG"
          onCancel={() => setVisibleModalScan(false)}
          scanResult={(result) => scanResult(result)}
        />
      </Modal>

      <Modal 
        title=''
        visible={visibleModalConfirm} 
        width={800}
        centered
        destroyOnClose={true}
        onCancel={() => setVisibleModalConfirm(false)}
        footer={null}
        >
        <Confirm text={`Bạn đã check ${type=='in'?'in':'out'} lúc ${type=='in'?(inOutByEmployeeDate.time_in && inOutByEmployeeDate.time_in.substring(0, 5))
                      :(inOutByEmployeeDate.time_out && inOutByEmployeeDate.time_out.substring(0, 5))}, Bạn có muốn đổi thời gian check ${type=='in'?'in':'out'} thành ${moment().format('HH:mm')} ?`}
          onCancel={cancelOveride}
          onConfirm={confirmOveride}
        />
      </Modal>
    </div>
  );
};

export default CheckInOut;
