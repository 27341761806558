import moment from 'moment';

export const API_URI = `${process.env.REACT_APP_SERVER_URL}/api/v1`;
export const API_HOST = `${process.env.REACT_APP_SERVER_URL}`;
export const SOCKET_URL = `${process.env.REACT_APP_SOCKET_URL}`;

export const STORAGE_UTM_ACCOUNT_TOKEN = 'utm-account-token';

export const STORAGE_UTM_ACCOUNT_NAME = 'utm-account-name';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mm:ss A zz';

// department id
export const DEPARTMENT_WHITELIST = 3;

export const fromEpochDateTimeFormat = (str) => moment.unix(str).format(DATE_TIME_FORMAT);

export const dateTimeFormat = (str) => moment(str).format(DATE_TIME_FORMAT);

export const dateFormat = (str) => moment(str).format(DATE_FORMAT);
