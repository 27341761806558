import {
  LAYOUT_TYPE_FULL,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK,
} from '../../constants/ThemeSetting';

const {
  nav_style = NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  locale = {
    languageId: 'vietnam',
    locale: 'vn',
    name: 'Vietnamese',
    icon: 'vn'
  }
} = JSON.parse(window.localStorage.getItem('setting')) || {};

const settings = {
  name: 'settings',
  state: {
    navStyle: nav_style,
    layoutType: LAYOUT_TYPE_FULL,
    themeType: THEME_TYPE_DARK,
    themeColor: '',

    isDirectionRTL: false,
    locale: locale,
    navCollapsed: true,
    width: window.innerWidth,
    pathName: '/employee',
  },
  reducers: {
    toggleCollapsedSideNav(state, payload) {
      return {
        ...state,
        navCollapsed: payload,
      };
    },

    updateWindowWidth(state, payload) {
      return {
        ...state,
        width: payload,
      };
    },
    setThemeType(state, payload) {
      return {
        ...state,
        themeType: payload,
      };
    },
    setThemeColor(state, payload) {
      return {
        ...state,
        themeColor: payload,
      };
    },
    onNavStyleChange(state, payload) {
      window.localStorage.setItem('setting', JSON.stringify({ ...JSON.parse(window.localStorage.getItem('setting')), nav_style: payload }));
      return {
        ...state,
        navStyle: payload,
      };
    },
    onLayoutTypeChange(state, payload) {
      return {
        ...state,
        layoutType: payload,
      };
    },
    switchLanguage(state, payload) {
      window.localStorage.setItem('setting', JSON.stringify({ ...JSON.parse(window.localStorage.getItem('setting')), locale: payload }));
      return {
        ...state,
        locale: payload,
      };
    },
  },
  effects: {},
};

export default settings;
