import store from 'appRedux/store';
import server from 'services/server';
import { API_URI } from 'constants/index'

const _ = {
  name: 'addUser',
  state: {
    item: {},
  }, // initial state
  reducers: {
    setData(state, payload) {
      return {
        ...state,
        item: payload,
      };
    },
  },
  effects: {
    /**
     * @param {Object} getParams
     * @param {int} getParams.id
     */
    async create(saveParams) {
      try {
        await server.post(`${API_URI}/users`, saveParams);
        
        return ['success'];
      } catch({response : { data: { message }, data}}) {
        if(typeof(message) !== 'undefined') {
          return [message];
        } else {
          return [JSON.stringify(data)];
        }
      }
    },
    async update(saveParams) {
      try {
        await server.put(`${API_URI}/users/${saveParams.id}`, saveParams);
        
        return ['success'];
      } catch({response : { data: { message }, data}}) {
        if(typeof(message) !== 'undefined') {
          return [message];
        } else {
          return [JSON.stringify(data)];
        }
      }
    }
  },
};

store.addModel(_);
