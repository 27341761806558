import React, {useState, useEffect} from 'react';
import ReactPDF,{ Page, Text, Image, Font, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import formatMoney from 'util/FormatMoney';
import moment from 'moment';

Font.register({
  family: "Roboto",
  fonts: [
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf"},
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf"},
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"},
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-italic-webfont.ttf", fontStyle: 'italic'},
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bolditalic-webfont.ttf", fontStyle: 'italic'},
  ]
});

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 20,
    fontFamily: 'Roboto',
    fontSize: 12
  },
  title: {
    fontSize: 16,
    textAlign: 'center',
    fontWeight: 600,
    marginTop: 10,
  },
  headerTable: {
    flexDirection: 'row',
    color: '#FFF',
    backgroundColor: '#ED1C24',
    textTransform: 'uppercase'
  },
  rowTable: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  border: {
    border: 1, 
    // borderBottomStyle: 'dashed'
  },
  borderR: {
    borderRight: 1, 
    borderRightStyle: 'dashed'
  },
  borderRBL: {
    borderRight: 1, 
    borderBottom: 1, 
    borderLeft: 1, 
    borderBottomStyle: 'dashed'
  },
  cell: {
    paddingHorizontal: 5, 
    paddingVertical: 3
  },
  width10: {
    width: '10%'
  },
  width12: {
    width: '12%'
  },
  width15: {
    width: '15%'
  },
  width20: {
    width: '20%'
  },
  width23: {
    width: '23%'
  },
  width25: {
    width: '25%'
  },
  width30: {
    width: '30%'
  },
  totalDate: {backgroundColor: '#2F74B5'},
  categoryGroup: {
    backgroundColor: '#A6A6A6'
  },
  colorWhite: {color: 'white'},
  fontWeight500: {
    fontWeight: 500
  },
  fontWeight700: {
    fontWeight: 700
  },
  textCenter: {
    textAlign: 'center', 
    // paddingHorizontal: 2
  },
  textLeft: {
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  textRight: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
});

const DeptPdf = () => {
  const data = JSON.parse(window.localStorage.getItem('pdf_dept_data'));
  const dataTable = data ? data.dataPdf : [];
  
  useEffect(() => {
    document.getElementById("root").setAttribute("style","overflow:hidden");
    
  }, []);
 
  return (
    <PDFViewer width='100%' height='100%'>
      <Document>
        <Page orientation='landscape' wrap={true} style={styles.body}>
          <View style={styles.title}>
            <Text>CÔNG NỢ</Text>
          </View>
          
          <View style={{...styles.textCenter, paddingBottom: 20}}>
            <Text>
              <Text>Từ {data.fromDate ? moment(data.fromDate).format('DD/MM/YYYY') : ''} đến {data.toDate ? moment(data.toDate).format('DD/MM/YYYY') : ''}</Text>
            </Text>
          </View>
          
          <View style={{...styles.table}}>
            <View fixed={true} style={{...styles.headerTable, ...styles.border, ...styles.fontWeight700}}>
              <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                <Text>Ngày</Text>
              </View>
              <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                <Text>Loại phiếu</Text>
              </View>
              <View style={{...styles.width25, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                <Text>Tên khách hàng/NCC</Text>
              </View>
              <View style={{...styles.width12, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                <Text>Số tiền</Text>
              </View>
              <View style={{...styles.width23, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                <Text>Lý do thanh toán</Text>
              </View>
              <View style={{...styles.width20, ...styles.textCenter, flexDirection: 'column', justifyContent: 'center'}}>
                <Text>Đính kèm</Text>
              </View>
            </View>
            
            { dataTable.length && dataTable.map((item, index) => {
              return (
                <>
                  <View wrap={false} style={{...styles.rowTable, ...styles.borderRBL, ...styles.colorWhite, ...styles.fontWeight500, ...styles.totalDate}}>
                    <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                      <Text>{item.date ? moment(item.date).format('DD/MM/YYYY') : ''}</Text>
                    </View>
                    <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                      <Text></Text>
                    </View>
                    <View style={{...styles.width25, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                      <Text></Text>
                    </View>
                    <View style={{...styles.width12, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                      <Text>{item.total ? formatMoney(item.total) : 0} Đ</Text>
                    </View>
                    <View style={{...styles.width23, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                      <Text></Text>
                    </View>
                    <View style={{...styles.width20, ...styles.textCenter, ...styles.cell}}>
                      <Text></Text>
                    </View>
                  </View>
                
                  //phieu thu
                  { item.debit > 0 &&
                    <View wrap={false} style={{...styles.rowTable, ...styles.borderRBL, ...styles.categoryGroup}}>
                      <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text></Text>
                      </View>
                      <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text>Phiếu Thu</Text>
                      </View>
                      <View style={{...styles.width25, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text></Text>
                      </View>
                      <View style={{...styles.width12, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text>{item.debit ? formatMoney(item.debit) : 0} Đ</Text>
                      </View>
                      <View style={{...styles.width23, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text></Text>
                      </View>
                      <View style={{...styles.width20, ...styles.textCenter, ...styles.cell}}>
                        <Text></Text>
                      </View>
                    </View>
                  }
                  
                  //chi tiet phieu thu: tien mat
                  { item.debit_children[1].total > 0 &&
                    <View wrap={false} style={{...styles.rowTable, ...styles.borderRBL}}>
                      <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text></Text>
                      </View>
                      <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text>{item.debit_children[1].title}</Text>
                      </View>
                      <View style={{...styles.width25, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text></Text>
                      </View>
                      <View style={{...styles.width12, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text>{item.debit_children[1].total ? formatMoney(item.debit_children[1].total) : 0} Đ</Text>
                      </View>
                      <View style={{...styles.width23, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text></Text>
                      </View>
                      <View style={{...styles.width20, ...styles.textCenter, ...styles.cell}}>
                        <Text></Text>
                      </View>
                    </View>
                  }
                  
                  { item.debit_children[1].total > 0 && item.debit_children[1].data && item.debit_children[1].data.length > 0 && item.debit_children[1].data.map((obj, indexObj) => {
                      return (
                        <View wrap={false} style={{...styles.rowTable, ...styles.borderRBL}}>
                          <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                            <Text></Text>
                          </View>
                          <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                            <Text></Text>
                          </View>
                          <View style={{...styles.width25, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                            <Text>{obj.name}</Text>
                          </View>
                          <View style={{...styles.width12, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                            <Text>{obj.total_money ? formatMoney(obj.total_money) : 0} Đ</Text>
                          </View>
                          <View style={{...styles.width23, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                            <Text>{obj.note}</Text>
                          </View>
                          <View style={{...styles.width20, ...styles.textCenter, ...styles.cell}}>
                            <Text>{obj.attach}</Text>
                          </View>
                        </View>
                      );
                    })
                  }
                  
                  //chi tiet phieu thu: chuyen khoan
                  { item.debit_children[2].total > 0 &&
                    <View wrap={false} style={{...styles.rowTable, ...styles.borderRBL}}>
                      <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text></Text>
                      </View>
                      <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text>{item.debit_children[2].title}</Text>
                      </View>
                      <View style={{...styles.width25, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text></Text>
                      </View>
                      <View style={{...styles.width12, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text>{item.debit_children[2].total ? formatMoney(item.debit_children[2].total) : 0} Đ</Text>
                      </View>
                      <View style={{...styles.width23, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text></Text>
                      </View>
                      <View style={{...styles.width20, ...styles.textCenter, ...styles.cell}}>
                        <Text></Text>
                      </View>
                    </View>
                  }
                  
                  { item.debit_children[2].total > 0 && item.debit_children[2].data && item.debit_children[2].data.length > 0 && item.debit_children[2].data.map((obj, indexObj) => {
                      return (
                        <View wrap={false} style={{...styles.rowTable, ...styles.borderRBL}}>
                          <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                            <Text></Text>
                          </View>
                          <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                            <Text></Text>
                          </View>
                          <View style={{...styles.width25, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                            <Text>{obj.name}</Text>
                          </View>
                          <View style={{...styles.width12, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                            <Text>{obj.total_money ? formatMoney(obj.total_money) : 0} Đ</Text>
                          </View>
                          <View style={{...styles.width23, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                            <Text>{obj.note}</Text>
                          </View>
                          <View style={{...styles.width20, ...styles.textCenter, ...styles.cell}}>
                            <Text>{obj.attach}</Text>
                          </View>
                        </View>
                      );
                    })
                  }
                  
                  //phieu chi
                  { item.credit > 0 &&
                    <View wrap={false} style={{...styles.rowTable, ...styles.borderRBL, ...styles.categoryGroup}}>
                      <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text></Text>
                      </View>
                      <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text>Phiếu Chi</Text>
                      </View>
                      <View style={{...styles.width25, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text></Text>
                      </View>
                      <View style={{...styles.width12, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text>{item.credit ? formatMoney(item.credit) : 0} Đ</Text>
                      </View>
                      <View style={{...styles.width23, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text></Text>
                      </View>
                      <View style={{...styles.width20, ...styles.textCenter, ...styles.cell}}>
                        <Text></Text>
                      </View>
                    </View>
                  }
                  
                  //chi tiet phieu chi: tien mat
                  { item.credit_children[1].total > 0 &&
                    <View style={{...styles.rowTable, ...styles.borderRBL}}>
                      <View wrap={false} style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text></Text>
                      </View>
                      <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text>{item.credit_children[1].title}</Text>
                      </View>
                      <View style={{...styles.width25, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text></Text>
                      </View>
                      <View style={{...styles.width12, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text>{item.credit_children[1].total ? formatMoney(item.credit_children[1].total) : 0} Đ</Text>
                      </View>
                      <View style={{...styles.width23, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text></Text>
                      </View>
                      <View style={{...styles.width20, ...styles.textCenter, ...styles.cell}}>
                        <Text></Text>
                      </View>
                    </View>
                  }
                  
                  { item.credit_children[1].total > 0 && item.credit_children[1].data && item.credit_children[1].data.length > 0 && item.credit_children[1].data.map((obj, indexObj) => {
                      return (
                        <View wrap={false} style={{...styles.rowTable, ...styles.borderRBL}}>
                          <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                            <Text></Text>
                          </View>
                          <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                            <Text></Text>
                          </View>
                          <View style={{...styles.width25, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                            <Text>{obj.name}</Text>
                          </View>
                          <View style={{...styles.width12, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                            <Text>{obj.total_money ? formatMoney(obj.total_money) : 0} Đ</Text>
                          </View>
                          <View style={{...styles.width23, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                            <Text>{obj.note}</Text>
                          </View>
                          <View style={{...styles.width20, ...styles.textCenter, ...styles.cell}}>
                            <Text>{obj.attach}</Text>
                          </View>
                        </View>
                      );
                    })
                  }
                  
                  //chi tiet phieu chi: chuyen khoan
                  { item.credit_children[2].total > 0 &&
                    <View wrap={false} style={{...styles.rowTable, ...styles.borderRBL}}>
                      <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text></Text>
                      </View>
                      <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text>{item.credit_children[2].title}</Text>
                      </View>
                      <View style={{...styles.width25, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text></Text>
                      </View>
                      <View style={{...styles.width12, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text>{item.credit_children[2].total ? formatMoney(item.credit_children[2].total) : 0} Đ</Text>
                      </View>
                      <View style={{...styles.width23, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text></Text>
                      </View>
                      <View style={{...styles.width20, ...styles.textCenter, ...styles.cell}}>
                        <Text></Text>
                      </View>
                    </View>
                  }
                  
                  { item.credit_children[2].total > 0 && item.credit_children[2].data && item.credit_children[2].data.length > 0 && item.credit_children[2].data.map((obj, indexObj) => {
                      return (
                        <View wrap={false} style={{...styles.rowTable, ...styles.borderRBL}}>
                          <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                            <Text></Text>
                          </View>
                          <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                            <Text></Text>
                          </View>
                          <View style={{...styles.width25, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                            <Text>{obj.name}</Text>
                          </View>
                          <View style={{...styles.width12, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                            <Text>{obj.total_money ? formatMoney(obj.total_money) : 0} Đ</Text>
                          </View>
                          <View style={{...styles.width23, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                            <Text>{obj.note}</Text>
                          </View>
                          <View style={{...styles.width20, ...styles.textCenter, ...styles.cell}}>
                            <Text>{obj.attach}</Text>
                          </View>
                        </View>
                      );
                    })
                  }
                </>
              );
            })}
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default DeptPdf;