import store from 'appRedux/store';
import server from 'services/server';
import { API_URI } from 'constants/index';

const INITIAL_STATE = {};

const UserDetail = {
  name: 'userDetail',
  state: INITIAL_STATE, // initial state
  reducers: {
    setData(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    /**
     * @param {Object} getParams
     * @param {int} getParams.id
     */
    async get({id}) {
      const { data } = await server.get(`${API_URI}/users/${id}`);
      this.setData(data);
    },
    /**
     * @param {Object} saveParams
     * @param {int} saveParams.id
     * @param {int} saveParams.last_name
     * @param {int} saveParams.first_name
     */
    async save({id, last_name, first_name}) {
      await server.put(`${API_URI}/users/${id}`, {
        last_name, first_name
      });
    },
    async updateAvatar(params) {
      const { data } = await server.post(`${API_URI}/users/updateAvatar`, params);
      return { data };
    },
  },
};

store.addModel(UserDetail);
