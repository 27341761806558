import store from 'appRedux/store';
import server from 'services/server';
import { API_URI } from 'constants/index'

export const TABLE_PER_PAGE = 12;

const UserList = {
  name: 'userList',
  state: {
    items: [],
    pagination: {},
    location: {},
    branch: [],
    condition: {}
  }, // initial state
  reducers: {
    setData(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    setCondition(state, payload) {
      return {
        ...state,
        condition: payload,
      };
    },
    setLocation(state, payload) {
      return {
        ...state,
        location: payload
      };
    },
    setBranch(state, payload) {
      return {
        ...state,
        branch: payload
      };
    }
  },
  effects: {
    async getList(queries) {
      const { data } = await server.get(`${API_URI}/auth/getList`, {
        params: {
          ...queries,
          limit: queries.limit ? queries.limit : TABLE_PER_PAGE
        },
      });
      this.setData(data);
      this.setCondition({...queries, limit: TABLE_PER_PAGE});
    },
    // async getAll(queries) {
    //   const { data } = await server.get(`${API_URI}/auth/getList`, {
    //     params: {
    //       ...queries,
    //     },
    //   });
    //   this.setData(data);
    // },
    async getBranchByCompanyId(queries) {
      const { data } = await server.get(`${API_URI}/branches`, {
        params: {
          ...queries
        },
      });
      this.setBranch(data.items);
      
      return data.items;
    },
  },
};

store.addModel(UserList);
