import React, {useEffect} from "react";
import { useHistory } from 'react-router-dom';
import { STORAGE_UTM_ACCOUNT_TOKEN as TOKEN } from 'constants/index';

const SignOut = () => {
  const history = useHistory();
  
  useEffect(() => {
    window.localStorage.removeItem(TOKEN);
    window.localStorage.clear();
    window.sessionStorage.clear();
    history.push('/signin');
  }, []);
  
  return (<></>);
};

export default SignOut;
