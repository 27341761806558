import React, { useState } from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "constants/ThemeSetting";
import IntlMessages from "util/IntlMessages";
import {useSelector} from "react-redux";
import { TeacherListQuery } from 'constants/default-queries';

const { SubMenu } = Menu;

const SidebarContent = ({sidebarCollapsed, setSidebarCollapsed}) => {
  const {navStyle, themeType} = useSelector(({settings}) => settings);
  const [popupClassName, setPopupClassName] = useState('');
  const {
    location: { query, search, pathname },
  } = useSelector((state) => state.router);
  
  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed}/>
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
            <UserProfile/>
          </div>
          <AppsNavigation/>
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">

            <SubMenu key="settings" icon={<i className="icon icon-widgets"/>} title="Settings">
              <Menu.Item key="warehouses">
                <Link to="/warehouses"><i className="icon icon-widgets"/>
                  <span>Warehouses</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="product-natures">
                <Link to="/product-natures"><i className="icon icon-widgets"/>
                  <span>Product Natures</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="units">
                <Link to="/units"><i className="icon icon-widgets"/>
                  <span>Units</span>
                </Link>
              </Menu.Item>
              
              <Menu.Item key="suppliers">
                <Link to="/suppliers"><i className="icon icon-widgets"/>
                  <span>Suppliers</span>
                </Link>
              </Menu.Item>
              
              <Menu.Item key="manufacturers">
                <Link to="/manufacturers"><i className="icon icon-widgets"/>
                  <span>Manufacturers</span>
                </Link>
              </Menu.Item>
              
              <Menu.Item key="countries">
                <Link to="/countries"><i className="icon icon-widgets"/>
                  <span>Countries</span>
                </Link>
              </Menu.Item>
              
              <Menu.Item key="product-groups">
                <Link to="/product-groups"><i className="icon icon-widgets"/>
                  <span>Product groups</span>
                </Link>
              </Menu.Item>
              
              <Menu.Item key="products">
                <Link to="/products"><i className="icon icon-widgets"/>
                  <span>Products</span>
                </Link>
              </Menu.Item>
            </SubMenu>
            
            <SubMenu key="transactions" icon={<i className="icon icon-widgets"/>} title="Transactions">
              <Menu.Item key="supplier-transactions">
                <Link to="/supplier-transactions"><i className="icon icon-widgets"/>
                  <span>Supplier transactions</span>
                </Link>
              </Menu.Item>
            </SubMenu>
            
            <Menu.Item key="user">
              <Link to="/user"><i className="icon icon-widgets"/>
                <span>User</span>
              </Link>
            </Menu.Item>

			      <Menu.Item key="customer">
              <Link to="/customers"><i className="icon icon-user"/>
                <span>Customers</span>
              </Link>
            </Menu.Item>
            
            <Menu.Item key="project">
              <Link to="/projects"><i className="icon icon-user"/>
                <span>Projects</span>
              </Link>
            </Menu.Item>
            
            <Menu.Item key="orders">
              <Link to="/orders"><i className="icon icon-widgets"/>
                <span>Orders</span>
              </Link>
            </Menu.Item>
            
            <Menu.Item key="receipts">
              <Link to="/receipts"><i className="icon icon-widgets"/>
                <span>Receipts</span>
              </Link>
            </Menu.Item>
            
            <Menu.Item key="national-administrative-directory">
              <Link to="/national-administrative-directory"><i className="icon icon-widgets"/>
                <span><IntlMessages id="sidebar.nationalAdministrativeDirectoryPage"/></span>
              </Link>
            </Menu.Item>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);

