import React, {useEffect, useState} from "react";
import {Button, Form, Input} from "antd";
import {useSelector, useDispatch} from "react-redux";
import { useHistory } from 'react-router-dom';
import QRCode from 'qrcode.react';
import moment from 'moment';

import '../model';
import '../style.css';
import '../../Quote/Create/model';
import '../../Quote/List/model';
import '../../Quote/List/modelTrello';

const ScanQRCode = (props) => {
  const history = useHistory();
  const { items } = useSelector((state) => state.workMonthDate);
  const { listOrderCheck } = useSelector((state) => state.orderList);
  
  const { 
    workMonthDate: { saveScanCode, setData },
    orderList: { checkDataOrder },
    orderDetail: { updateOrder },
    modelTrello: { pushUpdateListTrello, pushCardLabelTrello, pushRemoveLabelCardTrello, getCardTrello, updateNameCardTrello, pushCardCommentTrello, getDetailTrelloUser }
  } = useDispatch();
  
  const [qrWidth, setQrWidth] = useState(510);
  const [code, setCode] = useState('');
  const [checking, setChecking] = useState(0);
  
  const user = JSON.parse(window.localStorage.getItem('user'));
  const company = JSON.parse(window.localStorage.getItem('user_company'));
  const robotInfor = JSON.parse(window.localStorage.getItem('robot_infor'));
  
  useEffect(() => {
    if(user && user.username != 'system')
      history.push('/signin');
  }, []);
  
  useEffect(() => {
    if(window.innerWidth < qrWidth)
      setQrWidth(window.innerWidth - 150);

    const scode = makeid(50);
    setCode(scode);
    saveScanCode({scan_code: scode});

    const interval = setInterval(() => {
      const scode = makeid(50);
      setCode(scode);
      saveScanCode({scan_code: scode});
    }, 180000);//60000 = 1p
    
    // if(!robotInfor) {
    //   runRobot();
    // }
    
    // const intervalRobot = setInterval(() => {
    //   runRobot();
    // }, 3600000);  //3600000 = 1h
  
    return () => {  // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
      clearInterval(interval); 
      // clearInterval(intervalRobot); 
    };
  }, []);
  
  // useEffect(() => {
  //   if(listOrderCheck.length > 0) {
  //     setChecking(checking + 1);
  //   }
  // }, [listOrderCheck]);
  
  // useEffect(() => {
  //   setTimeout(() => {
  //     checkCardTrello();
  //   }, 5000);
  // }, [checking]);
  
  const checkCardTrello = async () => {
    if(checking > 0 && listOrderCheck.length > checking - 1) {
      const infor = listOrderCheck[checking - 1];
      
      // lấy thông tin thẻ từ trello
      if(infor.trello_card_id && company.use_trello == 1) {
        const params = {
          trello_card_id: infor.trello_card_id,
          api_key: company.api_key,
          token: company.token
        };
        
        const detail = await getCardTrello(params);
        
        // kiểm tra tên thẻ trên trello có giống với tên thẻ trên PM, nếu khác cập nhật lại tên thẻ trên trello
        if(Object.keys(detail).length > 0 && detail.name != infor.name_card) {
          const paramsName = {
            api_key: company.api_key,
            token: company.token,
            trello_card_id: infor.trello_card_id,
            name: infor.name_card
          };
          
          await updateNameCardTrello(paramsName);
        }
        
        // kiểm tra dán nhãn trên thẻ trello
        const arrLabelTrello = detail.idLabels;
        
        // nếu thẻ có trạng thái tạm ngưng, paused = 1, thêm nhãn Tạm Ngưng
        if(infor.paused == 1 && company.label_paused_card_id) {
          if(!arrLabelTrello.includes(company.label_paused_card_id)) { // chưa có nhãn Tạm Ngưng => thêm nhãn, có rồi thì bỏ qua
            addLabelTrello(infor.trello_card_id, company.label_paused_card_id);
          }
          
          // const arrLabelRemove = [
          //   company.label_connect_card_id,
          //   company.label_complete_card_id,
          //   company.label_finish_card_id,
          //   company.label_finish_debt_card_id,
          //   company.label_lock_card_id
          // ];
          
          // có các nhãn khác nhãn Tạm Ngưng => gỡ bỏ
          // arrLabelRemove.map(item => {
          //   if(arrLabelTrello.includes(item)) {
          //     removeLabelTrello(infor.trello_card_id, item);
          //   }
          // });
        } 
        
        if(infor.lock_status == 1) {  // nếu thẻ có trạng thái thẻ khóa, lock_status = 1, thêm nhãn Thẻ Khóa
          if(!arrLabelTrello.includes(company.label_lock_card_id)) { // chưa có nhãn Thẻ Khóa => thêm nhãn, có rồi thì bỏ qua
            addLabelTrello(infor.trello_card_id, company.label_lock_card_id);
          }
          
          // const arrLabelRemove = [
          //   company.label_connect_card_id,
          //   company.label_complete_card_id,
          //   company.label_finish_card_id,
          //   company.label_finish_debt_card_id,
          //   company.label_paused_card_id
          // ];
          
          // có các nhãn khác nhãn Thẻ Khóa => gỡ bỏ
          // arrLabelRemove.map(item => {
          //   if(arrLabelTrello.includes(item)) {
          //     removeLabelTrello(infor.trello_card_id, item);
          //   }
          // });
        } 
        
        if(infor.has_complete == 1) {  // nếu thẻ có trạng thái đã nghiệm thu, has_complete = 1, thêm nhãn Nghiệm Thu
          if(!arrLabelTrello.includes(company.label_complete_card_id)) { // chưa có nhãn Nghiệm Thu => thêm nhãn, có rồi thì bỏ qua
            addLabelTrello(infor.trello_card_id, company.label_complete_card_id);
          }
          
          // const arrLabelRemove = [
          //   company.label_connect_card_id,
          //   company.label_finish_card_id,
          //   company.label_finish_debt_card_id,
          //   company.label_lock_card_id,
          //   company.label_paused_card_id
          // ];
          
          // có các nhãn khác nhãn Nghiệm Thu => gỡ bỏ
          // arrLabelRemove.map(item => {
          //   if(arrLabelTrello.includes(item)) {
          //     removeLabelTrello(infor.trello_card_id, item);
          //   }
          // });
        } 
        
        // if(infor.has_complete != 1 && infor.lock_status != 1 && infor.paused != 1) { // nếu thẻ chưa nghiệm thu, ko bị khóa, ko tạm ngưng, thêm nhãn Linked To Portal
        if(infor.trello_card_id) {
          if(!arrLabelTrello.includes(company.label_connect_card_id)) { // chưa có nhãn Linked To Portal => thêm nhãn, có rồi thì bỏ qua
            addLabelTrello(infor.trello_card_id, company.label_connect_card_id);
          }
          
          // const arrLabelRemove = [
          //   company.label_complete_card_id,
          //   company.label_finish_card_id,
          //   company.label_finish_debt_card_id,
          //   company.label_lock_card_id,
          //   company.label_paused_card_id
          // ];
          
          // có các nhãn khác nhãn Linked To Portal => gỡ bỏ
          // arrLabelRemove.map(item => {
          //   if(arrLabelTrello.includes(item)) {
          //     removeLabelTrello(infor.trello_card_id, item);
          //   }
          // });
        }
        
        // kiểm tra thẻ nếu hết hạn thì khóa thẻ
        if(infor.lock_status == 0 && infor.paused == 0 && moment().isAfter(infor.time_expire)) {
    			await addLabelTrello(infor.trello_card_id, company.label_lock_card_id);
    			
    		// 	const arrLabelRemove = [
      //       company.label_connect_card_id,
      //       company.label_complete_card_id,
      //       company.label_finish_card_id,
      //       company.label_finish_debt_card_id,
      //       company.label_paused_card_id
      //     ];
          
          // có các nhãn khác nhãn Thẻ Khóa => gỡ bỏ
          // arrLabelRemove.map(item => {
          //   if(arrLabelTrello.includes(item)) {
          //     removeLabelTrello(infor.trello_card_id, item);
          //   }
          // });
          
          // comment
    			let reason = '';
    			
    			//thẻ đã có mở khóa
    			if(infor.unlock_at) {
    			  if(!infor.complete_at) { //chưa nghiệm thu, ko có TG complete_at
    			    //nếu TG hết hạn lớn hơn TG mở khóa thì hiển thị TG hết hạn
    			    //nếu TG hết hạn là thứ 2 (isoWeekday == 1) thì trừ đi 2 ngày để lấy TG cập nhật cuối cùng, các ngày khác thì trừ 1 ngày
    			    if(moment(infor.time_expire).isoWeekday() == 1 && moment(infor.time_expire).subtract(2, 'days').isAfter(infor.unlock_at)) {
    			      reason = '- **24h KHÔNG CẬP NHẬT**, thời gian cập nhật cuối cùng lúc **' + moment(infor.time_expire).subtract(2, 'days').format('HH:mm DD/MM') + '**';
    			    } else if(moment(infor.time_expire).isoWeekday() != 1 && moment(infor.time_expire).subtract(1, 'days').isAfter(infor.unlock_at)) {
    			      reason = '- **24h KHÔNG CẬP NHẬT**, thời gian cập nhật cuối cùng lúc **' + moment(infor.time_expire).subtract(1, 'days').format('HH:mm DD/MM') + '**';
    			    } else {
      			    reason = '- **24h KHÔNG HOẠT ĐỘNG** sau khi thẻ được **MỞ KHÓA**, thời gian mở khóa lúc **' + moment(infor.unlock_at).format('HH:mm DD/MM') + '**';
    			    }
      			} else {  //có nghiệm thu, có TG complete_at
      			  reason = '- **24h KHÔNG HOÀN TẤT HỒ SƠ** sau khi thẻ được **MỞ KHÓA**, thời gian mở khóa lúc **' + moment(infor.unlock_at).format('HH:mm DD/MM') + '**';
      			}
    			} else {  //thẻ chưa mở khóa lần nào
      			if(!infor.complete_at) { //chưa nghiệm thu, ko có TG complete_at
      			  //nếu TG hết hạn là thứ 2 (isoWeekday == 1) thì trừ đi 2 ngày để lấy TG cập nhật cuối cùng, các ngày khác thì trừ 1 ngày
      			  if(moment(infor.time_expire).isoWeekday() == 1)  // thứ 2
      			    reason = '- **24h KHÔNG CẬP NHẬT**, thời gian cập nhật cuối cùng lúc **' + moment(infor.time_expire).subtract(2, 'days').format('HH:mm DD/MM') + '**';
      			  else
      			    reason = '- **24h KHÔNG CẬP NHẬT**, thời gian cập nhật cuối cùng lúc **' + moment(infor.time_expire).subtract(1, 'days').format('HH:mm DD/MM') + '**';
      			} else {  //có nghiệm thu, có TG complete_at
      			  //nếu ngày hoàn thiện là thứ 7 (isoWeekday == 6) thì cần trừ 2 ngày từ time_expire
        			if(moment(infor.complete_at).isoWeekday() == 6 && moment(infor.time_expire).subtract(2, 'days').isAfter(infor.complete_at)) {
        			  reason = '- **24h KHÔNG CẬP NHẬT**, thời gian cập nhật cuối cùng lúc **' + moment(infor.time_expire).subtract(2, 'days').format('HH:mm DD/MM') + '**';
        			} else if(moment(infor.complete_at).isoWeekday() != 6 && moment(infor.time_expire).subtract(1, 'days').isAfter(infor.complete_at)) {
        			  reason = '- **24h KHÔNG CẬP NHẬT**, thời gian cập nhật cuối cùng lúc **' + moment(infor.time_expire).subtract(1, 'days').format('HH:mm DD/MM') + '**';
        			} else {
        			  reason = '- **24h KHÔNG HOÀN TẤT HỒ SƠ** sau khi **' + infor.user_complete + '** nghiệm thu, thời gian nghiệm thu lúc **' + moment(infor.complete_at).format('HH:mm DD/MM') + '**';
        			}
      			}
    			}
    			
          const paramsComment = {
    				trello_card_id: infor.trello_card_id,
    				text: '**THẺ KHÓA** \nLý do:\n ' + reason,
    				api_key: company.api_key,
    				token: company.token
    			};
    			
    			await pushCardCommentTrello(paramsComment);
          
          // cập nhật db lock_status = 1
          const paramsUpdate = {
            id: infor.id,
            lock_status: 1,
            lock_at: moment()
          };
          
          updateOrder(paramsUpdate);
        }
        
        // kiểm tra vị trí thẻ trello
        // thẻ đã nghiệm thu, ko được nằm trong DS Chưa Thanh Toán, Done, nếu nằm trong 2 DS kia thì chuyển về DS Xe Trong Ngày và ghi comment
        if(infor.has_complete == 1) {
          if(detail.idList == company.finish_debt_list_id || detail.idList == company.finish_list_id) {
            const paramsListName = {
      				trello_card_id: infor.trello_card_id,
      				idList: company.list_id,
      				api_key: company.api_key,
      				token: company.token
      			};
      			
      			await pushUpdateListTrello(paramsListName);
      			
      			// lấy thông tin user trello
      			let userTrello = '';
      			if(infor.user_id_trello) {
      			  const paramsUser = {
      			    member_id: infor.user_id_trello,
        				api_key: company.api_key,
        				token: company.token
      			  };
      			  
      			  userTrello = await getDetailTrelloUser(paramsUser);
      			}
      			
      			// comment
      			let reason = '';
      			
      			if(detail.idList == company.finish_debt_list_id) {
      			  reason = '- VUI LÒNG **HOÀN TẤT - CÔNG NỢ** TRƯỚC KHI CHUYỂN THẺ VÀO **' + company.finish_debt_list_name + '**' + (userTrello ? ' @' + userTrello.username : '');
      			} else if(detail.idList == company.finish_list_id) {
      			  reason = '- VUI LÒNG **HOÀN TẤT** TRƯỚC KHI CHUYỂN THẺ VÀO **' + company.finish_list_name + '**' + (userTrello ? ' @' + userTrello.username : '');
      			}
      			
      			const paramsComment = {
      				trello_card_id: infor.trello_card_id,
      				text: reason,
      				api_key: company.api_key,
      				token: company.token
      			};
      			
      			await pushCardCommentTrello(paramsComment);
          }
        } else {  // thẻ chưa nghiệm thu, ko được nằm trong DS Chờ Duyệt Kế Toán, Chưa Thanh Toán, Done, nếu nằm trong 3 DS kia thì chuyển về DS Xe Trong Ngày và ghi comment
          if(detail.idList == company.complete_list_id || detail.idList == company.finish_debt_list_id || detail.idList == company.finish_list_id) {
            const paramsListName = {
      				trello_card_id: infor.trello_card_id,
      				idList: company.list_id,
      				api_key: company.api_key,
      				token: company.token
      			};
      			
      			await pushUpdateListTrello(paramsListName);
      			
      			// lấy thông tin user trello
      			let userTrello = '';
      			if(infor.user_id_trello) {
      			  const paramsUser = {
      			    member_id: infor.user_id_trello,
        				api_key: company.api_key,
        				token: company.token
      			  };
      			  
      			  userTrello = await getDetailTrelloUser(paramsUser);
      			}
      			
      			// comment
      			let reason = '';
      			
      			if(detail.idList == company.complete_list_id) {
      			  reason = '- VUI LÒNG **NGHIỆM THU** TRƯỚC KHI CHUYỂN THẺ VÀO **' + company.complete_list_name + '**' + (userTrello ? ' @' + userTrello.username : '');
      			} else if(detail.idList == company.finish_debt_list_id) {
      			  reason = '- VUI LÒNG **HOÀN TẤT - CÔNG NỢ** TRƯỚC KHI CHUYỂN THẺ VÀO **' + company.finish_debt_list_name + '**' + (userTrello ? ' @' + userTrello.username : '');
      			} else if(detail.idList == company.finish_list_id) {
      			  reason = '- VUI LÒNG **HOÀN TẤT** TRƯỚC KHI CHUYỂN THẺ VÀO **' + company.finish_list_name + '**' + (userTrello ? ' @' + userTrello.username : '');
      			}
      			
      			const paramsComment = {
      				trello_card_id: infor.trello_card_id,
      				text: reason,
      				api_key: company.api_key,
      				token: company.token
      			};
      			
      			await pushCardCommentTrello(paramsComment);
          }
        }
        
        
        
      }
      
      setChecking(checking + 1);
    } else if(checking > 0) {
      setChecking(0);
      
      robotInfor.status = false;
      window.localStorage.setItem('robot_infor', JSON.stringify(robotInfor));
    }
  };
  
  const addLabelTrello = (cardId, labelId) => {
    const paramsLabel = {
			trello_card_id: cardId,
			value: labelId,
			api_key: company.api_key,
			token: company.token
		};
		
		pushCardLabelTrello(paramsLabel);
  };
  
  const removeLabelTrello = (cardId, labelId) => {
    const paramsLabel = {
			trello_card_id: cardId,
			trello_label_id: labelId,
			api_key: company.api_key,
			token: company.token
		};
		
		pushRemoveLabelCardTrello(paramsLabel);
  };

  function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
  
  const runRobot = () => {
    if(user && user.username == 'system') {
      if(!robotInfor || (robotInfor && (!robotInfor.status || moment().subtract(1, 'days').isAfter(robotInfor.run_at)))) {
        console.log('run robot at: ', moment().format('DD/MM/YYYY HH:mm'));
        
        const params = {
          status: true,
          run_at: moment()
        };
        
        window.localStorage.setItem('robot_infor', JSON.stringify(params));
        
        checkDataOrder();
      }
    }
  };

  return (
    <div className="gx-app-login-wrap bg" style={{backgroundImage: 'url(/assets/images/login-bg.jpg)', backgroundPosition: 'center'
        ,backgroundSize: 'cover', justifyContent: 'center', width: '100%'}}>
      <div className="gx-app-login-container" style={{width: '100%', maxWidth: '1360px'}}>
        <div className="gx-app-login-main-content bg-text" style={{width: '100%'}}>
          <div className="gx-app-logo" style={{textAlign: 'center', width: '100%'}}>
            <img alt="example" src="/assets/images/logo.jpg" style={{width: '350px'}} />
          </div>
          
          <div className="gx-app-login-content" style={{width: '50%', textAlign: 'center', paddingTop: '20px', paddingBottom: '0px'}}>
            <QRCode
              id='qrcode'
              value={'ccmfast?type=in&code='+code}
              size={qrWidth}
              level={'H'}
              includeMargin={true}
            />
            <div style={{width: '100%', textAlign: 'center', fontSize: '50px', fontWeight: 'bold', color: '#0073ff', marginTop: '10px'}}>CHECK IN</div>
          </div>

          <div className="gx-app-login-content" style={{width: '50%', textAlign: 'center', paddingTop: '20px', paddingBottom: '0px'}}>
            <QRCode
              id='qrcode'
              value={'ccmfast?type=out&code='+code}
              size={qrWidth}
              level={'H'}
              includeMargin={true}
            />
            <div style={{width: '100%', textAlign: 'center', fontSize: '50px', fontWeight: 'bold', color: '#0073ff', marginTop: '10px'}}>CHECK OUT</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScanQRCode;
