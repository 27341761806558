import store from 'appRedux/store';
import server from 'services/server';
import { API_URI } from 'constants/index'

export const TABLE_PER_PAGE = 12;

const _ = {
  name: 'workMonthDate',
  state: {
    items: [],
    list: [],
    lstWorkMonthDate: [],
    lstWorkDateByEmployee: [],
    dayInMonth: null,
    dayWork: 0,
    inOutByEmployeeDate: {},
    lstWorkMonthDateTotal: [],
    detail: {}
  }, // initial state
  reducers: {
    setData(state, payload) {
      return {
        ...state,
        items: payload,
      };
    },
    setList(state, payload) {
      return {
        ...state,
        list: payload
      };
    },
    setListWorkMonthDate(state, payload) {
      return {
        ...state,
        lstWorkMonthDate: payload
      };
    },
    setWorkDateByEmployee(state, payload) {
      return {
        ...state,
        ...payload
      };
    },
    setInOutByEmployeeDate(state, payload) {
      return {
        ...state,
        inOutByEmployeeDate: payload
      };
    },
    setListWorkMonthDateTotal(state, payload) {
      return {
        ...state,
        lstWorkMonthDateTotal: payload
      };
    },
    setDetail(state, payload) {
      return {
        ...state,
        detail: payload
      };
    }
  },
  effects: {
    async saveScanCode(saveParams) {
      const { data } = await server.post(`${API_URI}/scan-code/saveScanCode`, saveParams);
      return data;
    },
    async checkInOut(saveParams) {
      const { data } = await server.post(`${API_URI}/work-month-date/checkInOut`, saveParams);
      return data;
    },
    async getListDepartmentOfBranch(queries) {
      const { data } = await server.get(`${API_URI}/branches/listDepartment`, {
        params: {
          ...queries,
        },
      });
      this.setData(data);
    },
    async getList(queries) {
      const { data } = await server.get(`${API_URI}/work-month-date`, {
        params: {
          ...queries,
        },
      });
      this.setList(data);
    },
    async saveWorkMonthDate(saveParams) {
      const { data } = await server.post(`${API_URI}/work-month-date/saveWorkMonthDate`, saveParams);
      return data;
    },
    async calWorkMonthDate(saveParams) {
      const { data } = await server.post(`${API_URI}/work-month-date/calWorkMonthDate`, saveParams);
      return data;
    },
    async getWorkMonthDate(queries) {
      const { data } = await server.get(`${API_URI}/work-month-date/getWorkMonthDate`, {
        params: {
          ...queries,
        },
      });
      this.setListWorkMonthDate(data);
    },
    async getWorkDateByEmployee(queries) {
      const { data } = await server.get(`${API_URI}/work-month-date/getWorkDateByEmployee`, {
        params: {
          ...queries,
        },
      });
      this.setWorkDateByEmployee(data);
    },
    async getInOutByEmployeeDate(queries) {
      try {
        const { data } = await server.get(`${API_URI}/work-month-date/getInOutByEmployeeDate`, {
          params: {
            ...queries,
          },
        });
        this.setInOutByEmployeeDate(data);
      } catch (error) {
        this.setInOutByEmployeeDate({error: true});
      }
    },
    async getWorkMonthDateTotal(queries) {
      const { data } = await server.get(`${API_URI}/work-month-date/getWorkMonthDate`, {
        params: {
          ...queries,
        },
      });
      this.setListWorkMonthDateTotal(data);
    },
    async getWorkDateByEmployeeId(queries) {
      const { data } = await server.get(`${API_URI}/work-month-date/getByEmployeeId`, {
        params: {
          ...queries,
        },
      });
      this.setDetail(data);
    },
    async saveWorkMonthDateTotal(saveParams) {
      const { data } = await server.post(`${API_URI}/work-month-date/saveWorkMonthDateTotal`, saveParams);
      return data;
    },
    async confirmWorking(saveParams) {
      const { data } = await server.post(`${API_URI}/work-month-date/confirmWorking`, saveParams);
      return data;
    },
  },
};

store.addModel(_);
