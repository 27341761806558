import store from 'appRedux/store';
import server from 'services/server';
import { API_URI } from 'constants/index'

const SignUp = {
  name: 'signUp',
  state: {
    result: [],
  }, // initial state
  reducers: {
    setData(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  
  effects: {
    async signUp(queries) {
      const { data } = await server.post(`${API_URI}/users`, queries);
      this.setData(data);
      // console.log(data)
    },
  },
};

store.addModel(SignUp);
